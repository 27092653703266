import CCStaking from "../abis/CCStaking.json";
import { useReadContracts, useWriteContract } from "wagmi";
import { Chain } from "viem";
import { useMemo, useCallback } from "react";
import { BD, ZERO } from "./Numbers";

// Types
type UserState = [bigint, bigint, bigint]; // [stake, lastUpdate, totalEarned]
type GlobalState = [bigint, bigint]; // [totalStake, lastUpdate]

// Contract read config
const REFETCH_INTERVAL = 30000;

export function useStakingPool(
  address: `0x${string}`,
  account: `0x${string}` | undefined,
  chain: Chain
) {
  // Contract reads
  const { data: contractReads, refetch } = useReadContracts({
    contracts: [
      {
        abi: CCStaking,
        address,
        functionName: "usersState",
        args: [account],
      },
      {
        abi: CCStaking,
        address,
        functionName: "globalState",
        args: [],
      },
      {
        abi: CCStaking,
        address,
        functionName: "claimable",
        args: [account],
      },
    ],
    query: {
      refetchIntervalInBackground: true,
      refetchInterval: REFETCH_INTERVAL,
    },
  });

  // Extract and type contract read results
  const [userStateResult, globalStateResult, claimableResult] = contractReads || [];
  const userState = userStateResult?.result as UserState;
  const globalState = globalStateResult?.result as GlobalState;
  const claimableValue = claimableResult?.result as bigint;

  // Contract write functions
  const { writeContractAsync: sendStake, status: stakeState } = useWriteContract();
  const { writeContractAsync: sendUnstake, status: unstakeState } = useWriteContract();
  const { writeContractAsync: sendClaim, status: claimState } = useWriteContract();

  // Derived state calculations
  const ownStake = useMemo(
    () => (userState?.[0] ? BD(userState[0].toString()) : ZERO),
    [userState]
  );

  const totalStake = useMemo(
    () => (globalState?.[0] ? BD(globalState[0].toString()) : ZERO),
    [globalState]
  );

  const totalEarned = useMemo(
    () => (userState?.[2] ? BD(userState[2].toString()) : ZERO),
    [userState]
  );

  // Contract interactions
  const stake = useCallback(
    (amount: bigint, deadline: bigint, v: bigint, r: string, s: string) =>
      sendStake({
        abi: CCStaking,
        address,
        functionName: "stake",
        args: [amount, deadline, v, r, s],
        chain,
        account,
      }),
    [sendStake, address, chain, account]
  );

  const unstake = useCallback(
    (amount: bigint) =>
      sendUnstake({
        abi: CCStaking,
        address,
        functionName: "unstake",
        args: [amount],
        chain,
        account,
      }),
    [sendUnstake, address, chain, account]
  );

  const claim = useCallback(
    () =>
      sendClaim({
        abi: CCStaking,
        address,
        functionName: "claim",
        args: [],
        chain,
        account,
      }),
    [sendClaim, address, chain, account]
  );

  return {
    // Raw states
    userState,
    globalState,
    claimable: claimableValue,
    // Computed values
    ownStake,
    totalStake,
    totalEarned,
    // Transaction states
    stakeState,
    unstakeState,
    claimState,
    // Actions
    stake,
    unstake,
    claim,
    // Refetch
    refetch,
  };
}