import { useMemo } from "react";
import { parseEther } from "viem";
import CCDistr from "../abis/CCDistr.json";
import { useReadContracts, useWriteContract } from "wagmi";
import { Chain } from "viem";

const REFETCH_INTERVAL = 30000;
const MINT_COST = "0";

export function useDistribution(
	address: `0x${string}`,
	account: `0x${string}` | undefined,
	chain: Chain
) {
	const { data: contractReads, refetch } = useReadContracts({
		contracts: [
			{
				abi: CCDistr,
				address,
				functionName: "hasMinted",
				args: [account],
			},
		],
		query: {
			refetchIntervalInBackground: true,
			refetchInterval: REFETCH_INTERVAL,
		},
	});

	const [hasMintedResult] = contractReads || [];
	const hasMinted = hasMintedResult?.result as boolean;

	const { writeContractAsync: sendMint, status: mintState } = useWriteContract();

	const mint = useMemo(
		() => () =>
			sendMint({
				abi: CCDistr,
				address,
				functionName: "mint",
				value: parseEther(MINT_COST),
				chain,
				account,
			}),
		[sendMint, address, chain, account]
	);

	return {
		// Raw states
		hasMinted,
		mintState,
		// Actions
		mint,
		// Refetch
		refetch,
	};
}
