import { useEffect, useState, useRef } from "react";
import LayoutCard from "../../Base/LayoutCard";
import { AnimatePresence } from "framer-motion";
import Compete from "./components/Compete/Compete";
import Kitchen from "./components/Side/Kitchen";
import Alerts from "./components/Side/Alerts";
import Modal from "../../Base/Modals/Modal";
import Attack from "../../Base/Modals/Attack/Attack";
import Attack2 from "../../Base/Modals/Attack/Attack2";
import Attack3 from "../../Base/Modals/Attack/Attack3";
import AttackVictory from "../../Base/Modals/Attack/AttackVictory";
import Transaction from "../../Base/Modals/Transaction/Transaction";
import Transaction2 from "../../Base/Modals/Transaction/Transaction2";
import Transaction3 from "../../Base/Modals/Transaction/Transaction3";
import Purchase from "../../Base/Modals/Purchase/Purchase";
import Purchase2 from "../../Base/Modals/Purchase/Purchase2";
import PurchaseSuccess from "../../Base/Modals/Purchase/PurchaseSuccess";
import PurchaseLose from "../../Base/Modals/Purchase/PurchaseLose";
import TransactionLose from "../../Base/Modals/Transaction/TransactionLose";
import AttackFail from "../../Base/Modals/Attack/AttackFail";
import SetAttack from "../../Base/Modals/Kitchen/SetAttack";
import SetDefence from "../../Base/Modals/Kitchen/SetDefence";
import Boost from "../../Base/Modals/Kitchen/Boost";
import Lock from "../../Base/Modals/Kitchen/Lock";
import Board from "../../Base/Modals/Board/Board";
import AttackLose from "../../Base/Modals/Attack/AttackLose";
import GeneralInfo from "Base/Modals/Staking/GeneralInfo";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { printBD } from "interfaces/Numbers";
import ClaimAll from "Base/Modals/ClaimAll";
import Leagues from "../../Base/Modals/Leagues/Leagues";
import Tutorial from "../../Base/Modals/Tutorial/Tutorial";
import CookieBanner from "../../Base/Modals/CookieBanner/CookieBanner";
import Opponent, { CreateAllOpponents } from "interfaces/Opponent";
import axios from "axios";
import { Addresses } from "index";
import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";

export const fetchLeagueSizes = async (dataType) => {
	const CACHE_KEY = "leagueSizes";
	const CACHE_EXPIRATION = 20 * 1000;

	const key = `${CACHE_KEY}_${dataType}`;
	const cachedData = localStorage.getItem(key);
	const cachedTimestamp = localStorage.getItem(`${key}_timestamp`);

	if (cachedData && cachedTimestamp) {
		const now = new Date().getTime();
		if (now - parseInt(cachedTimestamp) < CACHE_EXPIRATION) {
			return JSON.parse(cachedData);
		}
	}

	try {
		console.log(`Fetching ${dataType} league sizes...`);
		const response = await axios.get(
			`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=${
				dataType === "cookie" ? "cki_leagues" : "fdg_leagues"
			}`
		);

		const data = response.data;
		localStorage.setItem(key, JSON.stringify(data));
		localStorage.setItem(`${key}_timestamp`, new Date().getTime().toString());
		return data;
	} catch (error) {
		console.error("Error fetching league sizes:", error);
		return cachedData ? JSON.parse(cachedData) : [];
	}
};

const adversaries = async (league, account, pool) => {
	if (!account || !pool) return [];

	try {
		console.log("Fetching NewOpponents...");
		const response = await axios.get(
			`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=recent_opponents&pool=${pool}&address=${account}&league=${league}`
		);
		// console.log("Fetched NewOpponents:", response.data);
		return response.data;
	} catch (error) {
		console.error("Error fetching opponents:", error);
		return JSON.parse("[]");
	}
};

const attacks = async (attacker, defender, attackNum) => {
	if (!attacker || !attackNum || !defender) return [];

	try {
		console.log("Fetching AttackOutcomes...");
		const response = await axios.get(
			`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=attack_outcomes&attacker=${attacker}&attack_number=${attackNum}&defender=${defender}`
		);
		return response.data;
	} catch (error) {
		console.error("Error fetching opponents:", error);
		return JSON.parse("[]");
	}
};

const calculateLeagueSize = (sizes, league, dataType) => {
	if (!sizes || !league) return 0;

	if (league < 60) {
		let totalCount = 0;
		sizes.forEach((item) => {
			const itemLeague = dataType === "cookie" ? item.cki_league : item.fdg_league;
			const itemCount = dataType === "cookie" ? item.cki_count : item.fdg_count;
			if (itemLeague <= league) {
				totalCount += itemCount;
			}
		});
		return totalCount;
	}

	const entry = sizes.find((item) =>
		dataType === "cookie" ? item.cki_league == league : item.fdg_league == league
	);
	return entry ? (dataType === "cookie" ? entry.cki_count : entry.fdg_count) : 0;
};

export default function Home(props) {
	const {
		addTx,
		modal,
		setAlert,
		mint,
		approveAll,
		setModal,
		ckiInfo,
		fdgInfo,
		soundEffects,
		cookieBanner,
		setCookieBanner,
		account,
		adBlockedCKI,
		newOpponents,
		defense,
		setDefence,
		ad,
		balCki,
		balFdg,
		ckiList,
		ckiUnused,
		fdgUnused,
		setDefenceState,
		setAd,
		setAdState,
		adDay,
		realTimeAdBal,
		ckiApprove,
		fdgList,
		adObj,
		approveState,
		searchState,
		createAttack,
		attackState,
		attackEvents,
		attackCount,
		claimAll,
		ckiStakeApy,
		fdgStakeApy,
		ckiLockedRaw,
		ckiLockedStake,
		ckiLockedTotal,
		ckiLockedTotalEarned,
		ckiStakedClaimable,
		ckiLockedClaimable,
		ckiLock,
		ckiLockState,
		ckiUnlock,
		ckiUnlockState,
		ckiLockedClaim,
		ckiLockedClaimState,
		ckiLockRev,
		ckiLeague,
		fdgLockedRaw,
		fdgLockedStake,
		fdgLockedTotal,
		fdgLockedTotalEarned,
		fdgLockedClaimable,
		fdgStakedClaimable,
		fdgLock,
		fdgLockState,
		gCkiChangeState,
		fdgUnlock,
		fdgUnlockState,
		fdgLockedClaim,
		fdgLockedClaimState,
		fdgLockRev,
		fdgLeague,
		ckiLockChangeEvents,
		fdgLockChangeEvents,
		ckiLockClaimEvents,
		fdgLockClaimEvents,
		ckiStakeChangeEvents,
		fdgStakeChangeEvents,
		ckiStakeClaimEvents,
		fdgStakeClaimEvents,
		changeGCkiEvents,
		ckiLockingClaimableRT,
		fdgLockingClaimableRT,
		gCkiDeposit,
		gCkiEnd,
		gCkiBal,
		changeGCki,
		withdraw,
		hasMinted,
		balance,
		fdgStakedTotal,
		ckiStakedTotal,
		ckiStakeRev,
		fdgStakeRev,
	} = props;

	const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";
	const checkConnection = () => {
		if (!account) {
			setModal("generalInfo");
			return false;
		}
		return true;
	};
	const { dataType } = useSelector((state) => state.common);

	const [leagueSizes, setLeagueSizes] = useState({
		cookie: {
			current: 0, // For lock mode
			target: 0, // For compete mode
		},
		fudge: {
			current: 0, // For lock mode
			target: 0, // For compete mode
		},
	});
	const [opponentsList, setOpponentsList] = useState({
		cookie: Array(8).fill(new Opponent(0, 100, 0, NULL_ADDRESS, 0, -1)),
		fudge: Array(8).fill(new Opponent(0, 100, 0, NULL_ADDRESS, 0, -1)),
	});
	const [opponents, setOpponents] = useState({
		cookie: new Opponent(0, 100, 0, NULL_ADDRESS, 0, -1),
		fudge: new Opponent(0, 100, 0, NULL_ADDRESS, 0, -1),
	});
	const [leagues, setLeagues] = useState({
		cookie: {
			current: 0, // For lock mode
			target: 0, // For compete mode TODO
		},
		fudge: {
			current: 0, // For lock mode
			target: 0, // For compete mode TODO
		},
	});
	const [types, setTypes] = useState({ cookie: "compete", fudge: "compete" });
	const [steps, setSteps] = useState({ cookie: "choose", fudge: "choose" });
	const [pollStates, setPollStates] = useState({});

	const setOpponent = (opponent) => {
		setOpponents((prev) => ({
			...prev,
			[dataType]: opponent,
		}));
	};
	const setTargetLeague = (league) => {
		setLeagues((prev) => ({
			...prev,
			[dataType]: {
				...prev[dataType],
				target: league,
			},
		}));
	};
	const setStep = (step) => {
		setSteps((prev) => ({
			...prev,
			[dataType]: step,
		}));
	};
	const setType = (type) => {
		setTypes((prev) => ({
			...prev,
			[dataType]: type,
		}));
	};

	useEffect(() => {
		if (ckiLeague !== undefined && fdgLeague !== undefined) {
			setLeagues((prev) => ({
				cookie: {
					current: ckiLeague,
					target: prev.cookie.target ? prev.cookie.target : ckiLeague,
				},
				fudge: {
					current: fdgLeague,
					target: prev.fudge.target ? prev.fudge.target : fdgLeague,
				},
			}));
		}
	}, [ckiLeague, fdgLeague]);

	useEffect(() => {
		if (!account) {
			setOpponentsList({
				cookie: Array(8).fill(new Opponent(0, 100, 0, NULL_ADDRESS, 0, -1)),
				fudge: Array(8).fill(new Opponent(0, 100, 0, NULL_ADDRESS, 0, -1)),
			});
			return;
		}

		const initializeStates = async () => {
			// Fetch league sizes for both types at once
			const [cookieSizes, fudgeSizes] = await Promise.all([
				fetchLeagueSizes("cookie"),
				fetchLeagueSizes("fudge"),
			]);

			// Update league sizes for both types
			const targetCookieSize = calculateLeagueSize(
				cookieSizes,
				leagues["cookie"].target,
				"cookie"
			);
			const targetFudgeSize = calculateLeagueSize(
				fudgeSizes,
				leagues["fudge"].target,
				"fudge"
			);
			setLeagueSizes({
				cookie: {
					current: calculateLeagueSize(cookieSizes, ckiLeague, "cookie"),
					target: targetCookieSize,
				},
				fudge: {
					current: calculateLeagueSize(fudgeSizes, fdgLeague, "fudge"),
					target: targetFudgeSize,
				},
			});

			// Fetch opponents for both types
			const [cookieOpponents, fudgeOpponents] = await Promise.all([
				adversaries(leagues["cookie"].target, account, Addresses.CCLockingCKI),
				adversaries(leagues["fudge"].target, account, Addresses.CCLockingFDG),
			]);

			const cookieOpponentsList = CreateAllOpponents(true, cookieOpponents);
			const fudgeOpponentsList = CreateAllOpponents(false, fudgeOpponents);

			setOpponentsList({ cookie: cookieOpponentsList, fudge: fudgeOpponentsList });
			setOpponents({ cookie: cookieOpponentsList[0], fudge: fudgeOpponentsList[0] });

			// Set steps noOpponents needOpponents choose
			const defineStep = (leagueSize, opponent) => {
				const pool =
					dataType === "cookie" ? Addresses.CCLockingCKI : Addresses.CCLockingFDG;
				const cachedSearch = localStorage.getItem(`search${league}_${pool}`);
				const cachedAttack = localStorage.getItem(`attack${league}_${pool}`);

				if (cachedSearch) {
					const pid = parseInt(cachedSearch);
					waitSearchResult({ pid, pool, searchLeague: league });
					return "search";
				}

				if (cachedAttack) {
					const attackData = JSON.parse(cachedAttack);
					const { attacker, defender, attackNum, pid } = attackData;
					waitAttackResult({
						pid,
						attacker,
						defender,
						attackLeague: league,
						pool,
						attackNum,
						display: false,
					});
					return "attack";
				}

				if (leagueSize === 0) {
					return "noOpponents";
				} else if (opponent.address === NULL_ADDRESS) {
					return "needOpponents";
				} else {
					return "choose";
				}
			};

			setSteps({
				cookie: defineStep(targetCookieSize, cookieOpponentsList[0]),
				fudge: defineStep(targetFudgeSize, fudgeOpponentsList[0]),
			});
		};

		initializeStates();
	}, [account, leagues]);

	const currentDataType = useRef(dataType);
	const currentLeagues = useRef(leagues);

	useEffect(() => {
		currentDataType.current = dataType;
	}, [dataType]);

	useEffect(() => {
		currentLeagues.current = leagues;
	}, [leagues]);

	const waitSearchResult = ({ pid, pool, searchLeague }) => {
		localStorage.setItem(`search${searchLeague}_${pool}`, pid.toString());

		if (pollStates[`search${searchLeague}_${pool}`]) {
			return;
		}

		setPollStates((prev) => ({
			...prev,
			[`${searchLeague}_${pool}`]: true,
		}));

		const pollNewOpponents = async () => {
			while (true) {
				const response = await adversaries(searchLeague, account, pool);

				if (response && response.length > 0 && response[0].pid !== pid) {
					const opponents = CreateAllOpponents(pool === Addresses.CCLockingCKI, response);
					localStorage.removeItem(`search${searchLeague}_${pool}`);
					setPollStates((prev) => ({
						...prev,
						[`search${searchLeague}_${pool}`]: false,
					}));
					const currentPool =
						currentDataType.current === "cookie"
							? Addresses.CCLockingCKI
							: Addresses.CCLockingFDG;
					if (
						currentLeagues.current[currentDataType.current].target === searchLeague &&
						pool === currentPool
					) {
						setOpponentsList((prev) => ({
							...prev,
							[currentDataType.current]: opponents,
						}));
						setOpponent(opponents[0]);
						setStep("choose");
					}
					break;
				}

				// Wait a second before trying again
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
		};

		pollNewOpponents();
	};

	const waitAttackResult = async ({
		pid,
		attacker,
		defender,
		attackLeague,
		pool,
		attackNum,
		display,
	}) => {
		localStorage.setItem(
			`attack${attackLeague}_${pool}`,
			JSON.stringify({
				attacker,
				defender,
				attackNum,
				pid,
			})
		);

		if (pollStates[`attack${attackLeague}_${pool}`]) {
			return;
		}

		setPollStates((prev) => ({
			...prev,
			[`attack${attackLeague}_${pool}`]: true,
		}));

		const pollNewOutcomes = async () => {
			while (true) {
				// TODO(URGENT): NEED ANOTHER WAY TO NONCE!!
				const response = await attacks(attacker, defender, attackNum);
				if (response && response.length > 0) {
					localStorage.removeItem(`attack${attackLeague}_${pool}`);
					setPollStates((prev) => ({
						...prev,
						[`attack${attackLeague}_${pool}`]: false,
					}));
					setTxInfo({
						description: response[0].defender,
						value: printBD(response[0].gain),
						token: pool === Addresses.CCLockingCKI ? "CKI" : "FDG",
						avatar: createAvatar(avataaarsNeutral, { seed: defender }).toString(),
					});

					if (display) {
						if (response[0].success) {
							soundEffects["attackWon"].play();
							setModal("attackVictory");
						} else {
							soundEffects["attackLost"].play();
							setModal("attackLose");
						}
					}

					const currentPool =
						currentDataType.current === "cookie"
							? Addresses.CCLockingCKI
							: Addresses.CCLockingFDG;
					if (
						currentLeagues.current[currentDataType.current].target === attackLeague &&
						pool === currentPool
					) {
						setStep("search");
					}
					waitSearchResult({ pid, pool, searchLeague: attackLeague });
					break;
				}

				// Wait a second before trying again
				await new Promise((resolve) => setTimeout(resolve, 1000));
			}
		};

		pollNewOutcomes();
	};

	const [txInfo, setTxInfo] = useState({ description: "", value: 0, action: "", token: "" });
	useEffect(() => {
		if (modal) {
			document.body.classList.add("active");
		} else {
			document.body.classList.remove("active");
		}
	}, [modal]);

	const type = types[dataType];
	const step = steps[dataType];
	const leagueSize =
		type === "lock" ? leagueSizes[dataType]?.current : leagueSizes[dataType]?.target;
	const league = type === "lock" ? leagues[dataType]?.current : leagues[dataType]?.target;
	const opponentList = opponentsList[dataType];
	const opponent = opponents[dataType];

	return (
		<>
			<main className="main">
				<div className="layout">
					<div className="auto__container">
						<div className="layout__inner">
							<div className="layout__inner-main">
								<div className="layout__inner-top">
									<LayoutCard
										type="board"
										league={dataType == "cookie" ? ckiLeague : fdgLeague}
										total={dataType == "cookie" ? ckiLockedRaw : fdgLockedRaw}
										onClick={() => {
											soundEffects["click"].play();
											setModal("board");
										}}
										token={dataType == "cookie" ? "CKI" : "FDG"}
									/>
									<LayoutCard type="season" status="progress" />
								</div>
								<Compete
									attackCount={attackCount}
									addTx={addTx}
									realTimeAdBal={realTimeAdBal}
									setAlert={setAlert}
									setModal={setModal}
									soundEffects={soundEffects}
									account={account}
									defence={defense}
									adObj={adObj}
									adBal={ad}
									createAttack={createAttack}
									newOpponents={newOpponents}
									ckiLockedRaw={ckiLockedRaw}
									ckiLockedStake={ckiLockedStake}
									ckiLockedTotal={ckiLockedTotal}
									ckiLockedTotalEarned={ckiLockedTotalEarned}
									ckiLock={ckiLock}
									ckiUnlock={ckiUnlock}
									ckiLockedClaim={ckiLockedClaim}
									ckiLockRev={ckiLockRev}
									fdgLockedRaw={fdgLockedRaw}
									fdgLockedStake={fdgLockedStake}
									fdgLockedTotal={fdgLockedTotal}
									fdgLockedTotalEarned={fdgLockedTotalEarned}
									fdgLock={fdgLock}
									fdgUnlock={fdgUnlock}
									fdgLockedClaim={fdgLockedClaim}
									fdgLockRev={fdgLockRev}
									setTxInfo={setTxInfo}
									ckiLockingClaimableRT={ckiLockingClaimableRT}
									fdgLockingClaimableRT={fdgLockingClaimableRT}
									hasMinted={hasMinted}
									leagueSize={leagueSize}
									league={league}
									setTargetLeague={setTargetLeague}
									type={type}
									opponent={opponent}
									opponentsList={opponentList}
									step={step}
									setStep={setStep}
									setOpponent={setOpponent}
									waitSearchResult={waitSearchResult}
									waitAttackResult={waitAttackResult}
									setType={setType}
								/>
							</div>
							<div className="layout__inner-side">
								<Kitchen
									account={account}
									setModal={setModal}
									setAlert={setAlert}
									adBlockedCKI={adBlockedCKI}
									defense={defense}
									realTimeAdBal={realTimeAdBal}
									gCkiDeposit={gCkiDeposit}
									gCkiEnd={gCkiEnd}
									gCkiBal={gCkiBal}
									gCKIBlockedCKI={gCkiDeposit}
									adDay={adDay}
									soundEffects={soundEffects}
									withdrawGCki={withdraw}
								/>
								<Alerts
									account={account}
									ckiLockChangeEvents={ckiLockChangeEvents}
									fdgLockChangeEvents={fdgLockChangeEvents}
									ckiLockClaimEvents={ckiLockClaimEvents}
									fdgLockClaimEvents={fdgLockClaimEvents}
									ckiStakeChangeEvents={ckiStakeChangeEvents}
									fdgStakeChangeEvents={fdgStakeChangeEvents}
									ckiStakeClaimEvents={ckiStakeClaimEvents}
									fdgStakeClaimEvents={fdgStakeClaimEvents}
									changeGCkiEvents={changeGCkiEvents}
									attackEvents={attackEvents}
									soundEffects={soundEffects}
								/>
							</div>
						</div>
					</div>
				</div>
			</main>
			<AnimatePresence>
				{modal === "attack" && checkConnection() && (
					<Modal setModal={setModal}>
						<Attack setModal={setModal} attackState={attackState} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attack2" && (
					<Modal setModal={setModal}>
						<Attack2 setModal={setModal} attackState={attackState} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attack3" && (
					<Modal setModal={setModal}>
						<Attack3 setModal={setModal} attackState={attackState} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attackVictory" && (
					<Modal setModal={setModal}>
						<AttackVictory setModal={setModal} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attackLose" && (
					<Modal setModal={setModal}>
						<AttackLose setModal={setModal} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attackFail" && (
					<Modal setModal={setModal}>
						<AttackFail setModal={setModal} attackState={attackState} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "transaction" && checkConnection() && (
					<Modal setModal={setModal}>
						<Transaction
							setModal={setModal}
							txInfo={txInfo}
							approveState={approveState}
							searchState={searchState}
						/>
					</Modal>
				)}
				{modal === "transaction2" && (
					<Modal setModal={setModal}>
						<Transaction2
							setModal={setModal}
							txInfo={txInfo}
							approveState={approveState}
							searchState={searchState}
						/>
					</Modal>
				)}
				{modal === "transaction3" && (
					<Modal setModal={setModal}>
						<Transaction3
							setModal={setModal}
							txInfo={txInfo}
							approveState={approveState}
							searchState={searchState}
						/>
					</Modal>
				)}
				{modal === "transactionLose" && (
					<Modal setModal={setModal}>
						<TransactionLose
							setModal={setModal}
							txInfo={txInfo}
							approveState={approveState}
							searchState={searchState}
						/>
					</Modal>
				)}
				{modal === "purchase" && checkConnection() && (
					<Modal setModal={setModal}>
						<Purchase
							setModal={setModal}
							txInfo={txInfo}
							setDefenceState={setDefenceState}
							setAdState={setAdState}
							ckiLockState={ckiLockState}
							ckiUnlockState={ckiUnlockState}
							ckiLockedClaimState={ckiLockedClaimState}
							fdgLockState={fdgLockState}
							fdgUnlockState={fdgUnlockState}
							fdgLockedClaimState={fdgLockedClaimState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "purchase2" && (
					<Modal setModal={setModal}>
						<Purchase2
							setModal={setModal}
							txInfo={txInfo}
							setDefenceState={setDefenceState}
							setAdState={setAdState}
							ckiLockState={ckiLockState}
							ckiUnlockState={ckiUnlockState}
							ckiLockedClaimState={ckiLockedClaimState}
							fdgLockState={fdgLockState}
							fdgUnlockState={fdgUnlockState}
							fdgLockedClaimState={fdgLockedClaimState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "purchaseSuccess" && (
					<Modal setModal={setModal}>
						<PurchaseSuccess
							setModal={setModal}
							txInfo={txInfo}
							setDefenceState={setDefenceState}
							setAdState={setAdState}
							ckiLockState={ckiLockState}
							ckiUnlockState={ckiUnlockState}
							ckiLockedClaimState={ckiLockedClaimState}
							fdgLockState={fdgLockState}
							fdgUnlockState={fdgUnlockState}
							fdgLockedClaimState={fdgLockedClaimState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "purchaseLose" && (
					<Modal setModal={setModal}>
						<PurchaseLose
							setModal={setModal}
							txInfo={txInfo}
							setDefenceState={setDefenceState}
							setAdState={setAdState}
							ckiLockState={ckiLockState}
							ckiUnlockState={ckiUnlockState}
							ckiLockedClaimState={ckiLockedClaimState}
							fdgLockState={fdgLockState}
							fdgUnlockState={fdgUnlockState}
							fdgLockedClaimState={fdgLockedClaimState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "setAttack" && (
					<Modal setModal={setModal}>
						<SetAttack
							account={account}
							setAlert={setAlert}
							approveAll={approveAll}
							setModal={setModal}
							ckiInfo={ckiInfo}
							setTxInfo={setTxInfo}
							ad={ad}
							addTx={addTx}
							setAd={setAd}
							adBlockedCKI={adBlockedCKI}
							cookieList={ckiList}
							balCki={balCki}
							ckiUnused={ckiUnused}
							ckiApprove={ckiApprove}
							withdrawing={false}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "withdrawAttack" && (
					<Modal setModal={setModal}>
						<SetAttack
							account={account}
							setAlert={setAlert}
							setModal={setModal}
							setTxInfo={setTxInfo}
							ad={ad}
							approveAll={approveAll}
							addTx={addTx}
							setAd={setAd}
							adBlockedCKI={adBlockedCKI}
							cookieList={ckiList}
							balCki={balCki}
							ckiUnused={ckiUnused}
							ckiApprove={ckiApprove}
							withdrawing={true}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "setDefence" && (
					<Modal setModal={setModal}>
						<SetDefence
							account={account}
							setAlert={setAlert}
							defence={defense}
							ckiInfo={ckiInfo}
							addTx={addTx}
							approveAll={approveAll}
							ckiLocked={ckiLockedRaw}
							fdgLocked={fdgLockedRaw}
							setDefence={setDefence}
							setModal={setModal}
							cookieList={ckiList}
							balCki={balCki}
							ckiUnused={ckiUnused}
							setTxInfo={setTxInfo}
							withdrawing={false}
							soundEffects={soundEffects}
							adding={true}
						/>
					</Modal>
				)}
				{modal === "withdrawDefence" && (
					<Modal setModal={setModal}>
						<SetDefence
							account={account}
							setAlert={setAlert}
							defence={defense}
							addTx={addTx}
							approveAll={approveAll}
							ckiLocked={ckiLockedRaw}
							fdgLocked={fdgLockedRaw}
							setDefence={setDefence}
							setModal={setModal}
							cookieList={ckiList}
							balCki={balCki}
							ckiUnused={ckiUnused}
							setTxInfo={setTxInfo}
							withdrawing={true}
							soundEffects={soundEffects}
							adding={false}
						/>
					</Modal>
				)}
				{modal === "boost" && (
					<Modal setModal={setModal}>
						<Boost
							account={account}
							setAlert={setAlert}
							setModal={setModal}
							addTx={addTx}
							ckiInfo={ckiInfo}
							approveAll={approveAll}
							list={ckiList}
							totalBal={balCki}
							ckiStake={ckiLockedStake}
							fdgStake={fdgLockedStake}
							ckiTotalStake={ckiLockedTotal}
							fdgTotalStake={fdgLockedTotal}
							ckiLockRev={ckiLockRev}
							fdgLockRev={fdgLockRev}
							rawCkiStake={ckiLockedRaw}
							rawFdgStake={fdgLockedRaw}
							unused={ckiUnused}
							gCkiDeposit={gCkiDeposit}
							gCkiEnd={gCkiEnd}
							gCkiBal={gCkiBal}
							changeGCki={changeGCki}
							withdraw={withdraw}
							setTxInfo={setTxInfo}
							soundEffects={soundEffects}
							user={account}
						/>
					</Modal>
				)}
				{modal === "lock" && (
					<Modal setModal={setModal}>
						<Lock
							account={account}
							setAlert={setAlert}
							locking={true}
							setModal={setModal}
							addTx={addTx}
							approveAll={approveAll}
							tokenInfo={dataType === "cookie" ? ckiInfo : fdgInfo}
							list={dataType === "cookie" ? ckiList : fdgList}
							totalBal={dataType === "cookie" ? balCki : balFdg}
							isLock={true}
							unused={dataType === "cookie" ? ckiUnused : fdgUnused}
							raw={dataType === "cookie" ? ckiLockedRaw : fdgLockedRaw}
							stake={dataType === "cookie" ? ckiLockedStake : fdgLockedStake}
							total={dataType === "cookie" ? ckiLockedTotal : fdgLockedTotal}
							lock={dataType === "cookie" ? ckiLock : fdgLock}
							unlock={dataType === "cookie" ? ckiUnlock : fdgUnlock}
							rev={dataType === "cookie" ? ckiLockRev : fdgLockRev}
							setTxInfo={setTxInfo}
							stakeApy={dataType === "cookie" ? fdgStakeApy : ckiStakeApy}
							gCkiBal={gCkiBal}
							defence={defense}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "unlock" && (
					<Modal setModal={setModal}>
						<Lock
							account={account}
							setAlert={setAlert}
							locking={false}
							setModal={setModal}
							approveAll={approveAll}
							addTx={addTx}
							list={dataType === "cookie" ? ckiList : fdgList}
							totalBal={dataType === "cookie" ? balCki : balFdg}
							isLock={false}
							unused={dataType === "cookie" ? ckiUnused : fdgUnused}
							raw={dataType === "cookie" ? ckiLockedRaw : fdgLockedRaw}
							stake={dataType === "cookie" ? ckiLockedStake : fdgLockedStake}
							total={dataType === "cookie" ? ckiLockedTotal : fdgLockedTotal}
							lock={dataType === "cookie" ? ckiLock : fdgLock}
							unlock={dataType === "cookie" ? ckiUnlock : fdgUnlock}
							rev={dataType === "cookie" ? ckiLockRev : fdgLockRev}
							setTxInfo={setTxInfo}
							stakeApy={dataType === "cookie" ? fdgStakeApy : ckiStakeApy}
							gCkiBal={gCkiBal}
							defence={defense}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "board" && (
					<Modal setModal={setModal}>
						<Board
							account={account}
							setModal={setModal}
							fdgLockRev={fdgLockRev}
							fdgLockedTotal={fdgLockedTotal}
							fdgLockedStake={fdgLockedStake}
							ckiLockedTotal={ckiLockedTotal}
							ckiLockedStake={ckiLockedStake}
							ckiLockRev={ckiLockRev}
							fdgStakeApy={fdgStakeApy}
							ckiStakeApy={ckiStakeApy}
							defense={defense}
							balCki={balCki}
							balFdg={balFdg}
							fdgStakedTotal={fdgStakedTotal}
							ckiStakedTotal={ckiStakedTotal}
							ckiStakeRev={ckiStakeRev}
							fdgStakeRev={fdgStakeRev}
						/>
					</Modal>
				)}
				{modal === "generalInfo" && (
					<Modal setModal={setModal}>
						<GeneralInfo
							setModal={setModal}
							title={"Connect your wallet"}
							content={
								<p>
									Welcome to CryptoCookies ! Remember to connect your wallet by
									clicking the top right button. Get more info
									<Link
										to="https://cryptocookies.gitbook.io/documentation/game-dynamics/getting-started"
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										here.
									</Link>
								</p>
							}
						/>
					</Modal>
				)}
				{modal === "welcomeBack" && (
					<Modal setModal={setModal}>
						<ClaimAll
							setModal={setModal}
							title={"Welcome back!"}
							user={account}
							addTx={addTx}
							claimAll={claimAll}
							soundEffects={soundEffects}
							content={
								<p>
									Good news ! Your Cookies and Fudge kept growing while you were
									gone. Claim your earning before you start playing.
									<br />
									{printBD(
										Number(ckiLockedClaimable) + Number(fdgStakedClaimable)
									)}{" "}
									CKI
									<br />
									{printBD(
										Number(fdgLockedClaimable) + Number(ckiStakedClaimable)
									)}{" "}
									FDG
								</p>
							}
						/>
					</Modal>
				)}
				{modal === "leagues" && (
					<Modal setModal={setModal}>
						<Leagues
							setModal={setModal}
							targetLeague={league}
							setTargetLeague={setTargetLeague}
						/>
					</Modal>
				)}
				{modal === "tutorial" && (
					<Modal setModal={setModal}>
						<Tutorial
							setModal={setModal}
							mint={mint}
							canMint={balCki == 0}
							soundEffects={soundEffects}
							account={account}
							setAlert={setAlert}
							balCki={balCki}
							balFdg={balFdg}
							hasMinted={hasMinted}
							balance={balance}
						/>
					</Modal>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{cookieBanner && (
					<Modal setModal={setCookieBanner}>
						<CookieBanner setModal={setCookieBanner} />
					</Modal>
				)}
			</AnimatePresence>
		</>
	);
}
