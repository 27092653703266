import { motion, AnimatePresence } from "framer-motion";
import { abbreviateNumber, getUnscaledBD, printBD, printUnscaledBD } from "interfaces/Numbers";
import React, { useEffect, useRef, useState } from "react";

import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";
import { TxIDs } from "./Modals/ModalWrapper";
import { Addresses } from "index";

export default function Notif(props) {
	const {
		fdgLockedClaimable,
		fdgStakedClaimable,
		ckiLockedClaimable,
		ckiStakedClaimable,
		markNotifsAsRead,
		addTx,
		claimAll,
		attackEvents,
		account,
		soundEffects,
	} = props;
	const wrapper = useRef(null);
	const [active, setActive] = useState(false);
	const toggleActive = (e) => {
		e.stopPropagation();
		soundEffects["click"].play();
		if (active) {
			markNotifsAsRead();
		}

		setActive(!active);
	};
	const [notifList, setNotifList] = useState([]);

	const claimText = `Claim all your in-game earnings: ${printBD(Number(ckiLockedClaimable) + Number(fdgStakedClaimable)) +
		"  CKI, " +
		printBD(Number(fdgLockedClaimable) + Number(ckiStakedClaimable)) +
		" FDG."
		}`;
	const empyList = [
		{
			id: 0,
			type: "period",
			time: "",
			text: claimText,
		},
	];

	useEffect(() => {
		const list = [empyList[0]];
		if (attackEvents && attackEvents.length > 0) {
			for (var i = 0; i < attackEvents.length; i++) {
				const data = attackEvents[i];
				if (data.block_timestamp > 0) {
					const time = new Date(Number(data.block_timestamp) * 1000);
					const hour = time.getHours();
					const minutes = time.getMinutes();
					const avatarAttacker = createAvatar(avataaarsNeutral, {
						seed: data.attacker,
					}).toString();
					const style =
						' style="width: 25px; height: 25px; border-radius: 100%; vertical-align: -7px;background-color: #d0eef4;"';

					const avatar =
						avatarAttacker.substring(0, 4) +
						style +
						avatarAttacker.substring(4, avatarAttacker.length - 6) +
						"<title>" +
						data.attacker +
						"</title>" +
						"</svg>";

					const adLoot = Number(data.gain) == 0 ? printBD(data.apused / 2) : 0;
					const gain = Number(data.gain) == 0 ? "Won" : `-${printBD(data.gain)}`;
					list.push({
						id: i + 1,
						time: `${hour}:${minutes < 10 ? "0" + minutes : minutes}`,
						type: "attack",
						text: `Attacked by ${avatar} (${abbreviateNumber(
							getUnscaledBD(data.apused)
						)} DGH)`,
						lost: gain,
						ad: `+${adLoot} DGH`,
						token: data.pool == Addresses.CCLockingCKI ? "CKI" : "FDG",
						new: data.new,
					});
				}
			}
		}
		setNotifList(list);
	}, [attackEvents]);

	useEffect(() => {
		function handleClickOutside(event) {
			if (wrapper.current && !wrapper.current.contains(event.target)) {
				setActive(false);
				markNotifsAsRead();
			}
		}

		if (active) {
			document.addEventListener("mousedown", handleClickOutside, true);
			document.addEventListener("touchstart", handleClickOutside, true);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside, true);
			document.removeEventListener("touchstart", handleClickOutside, true);
		};
	}, [active]);

	return (
		<div className={"notif"} ref={wrapper}>
			<button className={"notifBtn " + (active ? "active" : "")} onClick={toggleActive}>
				<div className="notifBtn__icon">
					<img src="./images/icons/bell.svg" alt="" />
					{notifList.filter((item) => item.new).length > 0 && (
						<span>{notifList.filter((item) => item.new).length}</span>
					)}
				</div>
			</button>
			<AnimatePresence>
				{active === true && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.2 }}
						exit={{ opacity: 0 }}
						className={`notifDrop  ${active ? "active" : ""} `}
					>
						<h5>My Notifications</h5>
						<div className="notifDrop__checks">
							<div className="notifDrop__check">
								<input type="checkbox" defaultChecked />
								<label htmlFor=""></label>
								<p>Attacks</p>
							</div>
							{/*s<div className="notifDrop__check">
								<input type="checkbox" defaultChecked />
								<label htmlFor=""></label>
								<p>gCKI Releases</p>
							</div>*/}
						</div>
						<div className="notifDrop__inner">
							{notifList.map((item, index) => {
								return (
									<div
										className={
											"notifDrop__item " +
											(item.type === "period" ? "period" : "")
										}
										key={index}
									>
										<div className="notifDrop__item-left">
											<div className="notifDrop__item-left-time">
												{item.type === "attack" && item.new && (
													<span></span>
												)}
												<p className="sm">{item.time}</p>
											</div>
											<p
												className="sm"
												dangerouslySetInnerHTML={{ __html: item.text }}
											>
												{/* {item.text} */}
											</p>
										</div>
										<div className="notifDrop__item-right">
											{item.type === "period" && (
												<button
													className="button secondary"
													onClick={async () => {
														soundEffects["claim"].play();
														addTx(
															TxIDs.CLAIM_ALL,
															"Claiming rewards",
															"Claiming All",
															""
														);

														await claimAll(account);
													}}
												>
													Claim All
												</button>
											)}
											{item.type === "attack" && (
												<>
													{item.lost != "Won" && (
														<div
															className={
																"notifDrop__item-right-tag red"
															}
														>
															{item.lost} {item.token}
														</div>
													)}
													{item.lost == "Won" && (
														<div
															className={
																"notifDrop__item-right-tag green"
															}
														>
															{item.lost}
														</div>
													)}
													{item.lost === "Won" && (
														<div className="notifDrop__item-right-tag orange">
															{item.ad}
														</div>
													)}
												</>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}
