import { useCallback } from "react";
import { useReadContracts, useWriteContract } from "wagmi";
import { Chain } from "viem";
import CKI from "../abis/Cki.json";

const REFETCH_INTERVAL = 30000;

export function useERC20(
	address: `0x${string}`,
	account: `0x${string}` | undefined,
	chain: Chain
) {
	const { data: contractReads, refetch } = useReadContracts({
		contracts: [
			{
				abi: CKI,
				address,
				functionName: "balanceOf",
				args: [account],
			},
			{
				abi: CKI,
				address,
				functionName: "nonces",
				args: [account],
			},
			{
				abi: CKI,
				address,
				functionName: "name",
				args: [],
			},
		],
		query: {
			refetchIntervalInBackground: true,
			refetchInterval: REFETCH_INTERVAL,
		},
	});

	const [balanceResult, nonceResult, nameResult] = contractReads || [];
	const balance = balanceResult?.result as bigint;
	const nonce = nonceResult?.result as bigint;
	const name = nameResult?.result;

	const { writeContractAsync: sendMint } = useWriteContract();
	const { writeContractAsync: sendApprove, status: approveState } = useWriteContract();

	const mint = useCallback(
		(amount: bigint) =>
			sendMint({
				abi: CKI,
				address,
				functionName: "devMint",
				args: [amount],
				chain,
				account,
			}),
		[sendMint, address, chain, account]
	);

	const approve = useCallback(
		(spender: `0x${string}`, amount: bigint) =>
			sendApprove({
				abi: CKI,
				address,
				functionName: "approve",
				args: [spender, amount],
				chain,
				account,
			}),
		[sendApprove, address, chain, account]
	);

	return {
		// Raw states
		balance: balance ?? 0n,
		nonce: nonce ?? 0n,
		approveState,
		name,
		// Actions
		approve,
		mint,
		// Refetch
		refetch,
	};
}