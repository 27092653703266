import { useReadContracts, useWriteContract } from "wagmi";
import { Chain } from "viem";
import { useEffect, useState, useMemo } from "react";
import { BD } from "./Numbers";
import { computeRatio } from "App";
import CkiDistr from "../abis/CkiDistr.json";
import FdgDistr from "../abis/FdgDistr.json";
import UtilsAbi from "../abis/Utils.json";

const REFETCH_INTERVAL = 100000;
const ONE_DAY_SECONDS = 60 * 60 * 24;

export function useUtils(
	addressCki: `0x${string}`,
	addressFdg: `0x${string}`,
	addressUtils: `0x${string}`,
	account: `0x${string}` | undefined,
	chain: Chain
) {
	const { data: contractReads, refetch } = useReadContracts({
		contracts: [
			{
				abi: CkiDistr,
				address: addressCki,
				functionName: "revenue",
				args: [ONE_DAY_SECONDS],
			},
			{
				abi: FdgDistr,
				address: addressFdg,
				functionName: "revenue",
				args: [ONE_DAY_SECONDS],
			},
		],
		query: {
			refetchIntervalInBackground: true,
			refetchInterval: REFETCH_INTERVAL,
		},
	});

	const [ckiRevenueResult, fdgRevenueResult] = contractReads || [];
	const ckiRevenue = ckiRevenueResult?.result as bigint;
	const fdgRevenue = fdgRevenueResult?.result as bigint;

	const { writeContractAsync: sendClaimAll, status: claimAllState } = useWriteContract();
	const { writeContractAsync: sendAddGCki, status: gCkiChangeState } = useWriteContract();

	const ckiRev = useMemo(
		() => (ckiRevenue ? BD(ckiRevenue.toString()) : BD(0)),
		[ckiRevenue]
	);

	const fdgRev = useMemo(
		() => (fdgRevenue ? BD(fdgRevenue.toString()) : BD(0)),
		[fdgRevenue]
	);

	const ckiStakeRev = useMemo(() => fdgRev.multiply(BD("0.25")), [fdgRev]);
	const fdgStakeRev = useMemo(() => ckiRev.multiply(BD("0.25")), [ckiRev]);
	const ckiLockRev = useMemo(() => ckiRev.multiply(BD("0.75")), [ckiRev]);
	const fdgLockRev = useMemo(() => fdgRev.multiply(BD("0.75")), [fdgRev]);

	const claimAll = useMemo(
		() => (user: string) =>
			sendClaimAll({
				abi: UtilsAbi,
				address: addressUtils,
				functionName: "claimAll",
				args: [user],
				chain,
				account,
			}),
		[sendClaimAll, addressUtils, chain, account]
	);

	const deposit = useMemo(
		() => (period: bigint, amount: bigint, deadline: bigint, v: bigint, r: string, s: string, owner: string) =>
			sendAddGCki({
				abi: UtilsAbi,
				address: addressUtils,
				functionName: "addGCkiAndUpdatePools",
				args: [period, amount, deadline, v, r, s, owner],
				chain,
				account,
			}),
		[sendAddGCki, addressUtils, chain, account]
	);

	return {
		// Revenue values
		ckiRev,
		fdgRev,
		ckiStakeRev,
		fdgStakeRev,
		ckiLockRev,
		fdgLockRev,
		// Transaction states
		claimAllState,
		gCkiChangeState,
		// Actions
		claimAll,
		deposit,
		// Refetch
		refetch,
	};
}

// Utility functions
export function getLeagueBoost(league: number) {
	if (league < 0) return 0;
	return Math.tanh((league - 75) / 10) + 1.75;
}

export function getGCki(ckiDep: number, daysBlocked: number) {
	return (2 * (ckiDep * daysBlocked)) / 28;
}

export function getLockStrength(newStake: any, gCkiBal: any) {
	let league = Math.floor(Math.log2(newStake)) + 1;
	if (!isFinite(league)) league = 0;

	const leagueBoost = getLeagueBoost(league);
	const boostMultiplier = Number(newStake) * Number(gCkiBal);

	return leagueBoost * (Number(newStake) + 2 * Number(Math.sqrt(boostMultiplier)));
}

export function useRealTimeBalance(
	claimableValue: bigint | undefined,
	ownStakeHk: bigint | undefined,
	totalStakeHk: bigint | undefined,
	rev: bigint | undefined,
	timestamp: bigint | undefined
) {
	const [claimable, setClaimable] = useState<bigint | undefined>(claimableValue);

	useEffect(() => {
		// console.log(claimableValue?.toString())
		const ownStake = ownStakeHk ? BD(ownStakeHk.toString()) : BD(0);
		const totalStake = totalStakeHk ? BD(totalStakeHk.toString()) : BD(0);
		const ratio = computeRatio(ownStake, totalStake);

		if (claimableValue !== undefined && timestamp !== undefined && rev !== undefined) {
			const refreshRate = 1000;
			const rate = Number(rev) / (24 * 60 * 60 * 1000);

			const intervalId = setInterval(() => {
				const localElapsed = new Date().getTime() - (Number(timestamp) * 1000);
				const newValue = BigInt(claimableValue) +
					BigInt(Math.ceil((rate * localElapsed * ratio) / 100));
				setClaimable(newValue);
			}, refreshRate);

			return () => clearInterval(intervalId);
		} else {
			setClaimable(claimableValue);
		}
	}, [claimableValue, ownStakeHk, totalStakeHk, rev]);

	return claimable;
}

export function useRealTimeAD(
	ad: bigint | undefined,
	attackCki: bigint | undefined,
	timestamp: Date | undefined
) {
	const [adBal, setAdBal] = useState<bigint | undefined>(ad);

	useEffect(() => {
		if (ad !== undefined && attackCki !== undefined && timestamp !== undefined) {
			const adDay = BD(attackCki.toString()).multiply(BD(24));
			const refreshRate = 1000;
			const rate = Number(adDay) / (24 * 60 * 60 * 1000);

			const intervalId = setInterval(() => {
				const localElapsed = new Date().getTime() - (Number(timestamp) * 1000);
				const newValue = BigInt(ad) + BigInt(Math.ceil(rate * localElapsed));
				setAdBal(newValue);
			}, refreshRate);

			return () => clearInterval(intervalId);
		} else {
			setAdBal(ad);
		}
	}, [ad, attackCki]);

	return adBal;
}