import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./store/store";
import { createConfig, WagmiProvider } from "wagmi";

import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector";
import { ZeroDevSmartWalletConnectors } from "@dynamic-labs/ethereum-aa";

import { arbitrumSepolia } from "viem/chains";
import { http } from "viem";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export const Addresses = {
	CKI: "0xc61587Be377fb92955e64BeF872b166Fe1884D26",
	FDG: "0x2790c70EEF5886a5cA8d5d7c09f26D6DED50fcA4",
	GCKI: "0x4328239D7aBC94B613c7d18A383A34511405Ab60",
	DGH: "0xBC1DC782daC366A45d7F17890b6a036e5Bed6aed",
	CkiDistr: "0x1c1874F18eCf99280DC60a4103E1F2E4b1E26C37",
	FdgDistr: "0x17e02Bdae5Ddc9b32083d2Af5EbD7c083D444623",
	CCDistr: "0x8bc106eD177A93ce3D1d658C7be64d00fD2822df",
	CCStakingCKI: "0x3eAa901DAE78E3BF1f5A88C8ef2c56f26A596403",
	CCStakingFDG: "0x185ad9CE4Ee383c9F05d07C1204E290D6baB887F",
	CCCompete: "0x9958fE873b83135baD0Cf763651832BD4a4c8894",
	vrfCoordinator: "0x50d47e4142598E3411aA864e08a44284e471AC6f",
	CCLockingCKI: "0x45341Ab404a6D36a54b4d1bDe619c9855E714286",
	CCLockingFDG: "0x7473DBB22Ee087b00aA103fcF6EeD0132aB56dD8",
	CCPoolHandler: "0xbf70329Fe1913F64ff908c6F6a11D7a03906273A",
	Utils: "0xe30c7EAbD0DcF7F04484CE5B89D56194EED411a8",
};

export const SeasonDurationDays = 28;
export const SeasonStart = new Date("2025-01-22 14:00:00.243755+00");

export const CKI = Addresses.CKI;
export const FDG = Addresses.FDG;
export const CCLockingFDG = Addresses.CCLockingFDG;
export const CCStakingFDG = Addresses.CCStakingFDG;
export const CCLockingCKI = Addresses.CCLockingCKI;
export const CCStakingCKI = Addresses.CCStakingCKI;
export const AP = Addresses.DGH;
export const CCCompete = Addresses.CCCompete;

export const config = createConfig({
	chains: [arbitrumSepolia],
	multiInjectedProviderDiscovery: false,
	transports: {
		[arbitrumSepolia.id]: http(),
	},
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<DynamicContextProvider
					settings={{
						environmentId: "3e29d4b4-adac-473a-b819-a287cc4bc81d",
						walletConnectors: [EthereumWalletConnectors, ZeroDevSmartWalletConnectors],
						blockchains: "arbitrum-sepolia",
						initialAuthenticationMode: "connect-and-sign",
					}}
				>
					<WagmiProvider config={config}>
						<QueryClientProvider client={queryClient}>
							<DynamicWagmiConnector>
								<App />
							</DynamicWagmiConnector>
						</QueryClientProvider>
					</WagmiProvider>
				</DynamicContextProvider>
			</PersistGate>
		</Provider>
	</BrowserRouter>
);
