import React from "react";
import { infoIcon } from "../../../../../Base/SVG";

export default function Progress({ onClick = null, title1, title2 }) {
  return (
    <div className="competeProgress">
      <div className="competeProgress__item">
        <div className="competeProgress__item-circle" onClick={onClick}></div>
        <div className="competeProgress__item-text">
          <p>{title1}</p>
        </div>
      </div>
      <span className="competeProgress__line">
        <span></span>
      </span>
      <div className="competeProgress__item">
        <div className="competeProgress__item-circle"></div>
        <div className="competeProgress__item-text">
          <p>{title2}</p>
        </div>
      </div>
    </div>
  );
}
