import { refreshIcon } from "Base/SVG";
import { Addresses } from "index";
import { searchOpponents } from "../Compete";

export default function NoOpponents({
    league,
    account,
    adBal,
    newOpponents,
    addTx,
    setStep,
    step,
    dataType,
    setAlert,
    soundEffects,
    waitSearchResult
}) {
    return (
        <div className="competeBody__noplayers">
            <div className="competeBody__noplayers-title">Find Your Opponent!</div>
            <div className="competeBody__noplayers-image">
                <img className="swim2"
                    src={
                        process.env.PUBLIC_URL + "/images/dice.png"
                    }
                    alt="" />
            </div>
            <div className="competeBody__noplayers-content">
                <p>
                    Draw 8 random opponents in this league.
                </p>
            </div>
            <button className="button primary noOpponents"
                onClick={
                    async () => {
                        if (account === undefined) {
                            soundEffects["error"].play();
                            setAlert("Please connect your wallet.");
                            return;
                        }

                        if (Number(adBal) < (4 * 1e18)) {
                            soundEffects["error"].play();
                            setAlert("You have less than 4 DGH.");
                            return;
                        }

                        soundEffects["search"].play(); 
                        setStep("search");
                        await searchOpponents(waitSearchResult, newOpponents, league, dataType == "cookie" ? Addresses.CCLockingCKI : Addresses.CCLockingFDG, addTx, -1, setStep, step);
                    }
                }>
                {refreshIcon}
                Search Opponents &nbsp;
                <span style={
                    {
                        fontSize: "small",
                        fontWeight: "normal"
                    }
                }>
                    (4 DGH)</span>
            </button>
        </div>
    );
}
