import { useEffect, useState } from "react";
import { infoIcon } from "../../Base/SVG";
import Alerts from "../Home/components/Side/Alerts";
import Balance from "./Balance";
import Modal from "../../Base/Modals/Modal";
import StakeCKI from "../../Base/Modals/Staking/StakeCKI";
import UnStakeCKI from "../../Base/Modals/Staking/UnStakeCKI";
import { AnimatePresence } from "framer-motion";
import StakeFDG from "../../Base/Modals/Staking/StakeFDG";
import UnStakeFDG from "../../Base/Modals/Staking/UnStakeFDG";
import Purchase from "../../Base/Modals/Purchase/Purchase";
import Purchase2 from "../../Base/Modals/Purchase/Purchase2";
import PurchaseSuccess from "../../Base/Modals/Purchase/PurchaseSuccess";
import PurchaseLose from "../../Base/Modals/Purchase/PurchaseLose";
import GeneralInfo from "../../Base/Modals/Staking/GeneralInfo";
import { BD, printBD } from "interfaces/Numbers";
import { Link } from "react-router-dom";
import Tutorial from "Base/Modals/Tutorial/Tutorial";
import { TxIDs } from "Base/Modals/ModalWrapper";

export default function Staking(props) {
	const {
		addTx,
		setAlert,
		modal,
		setModal,
		account,
		soundEffects,
		balCki,
		ckiInfo,
		balFdg,
		fdgInfo,
		ckiList,
		fdgList,
		ckiApprove,
		ckiStaked,
		ckiStakeRev,
		ckiTotalStaked,
		fdgStaked,
		fdgStakeRev,
		fdgTotalStaked,
		fdgClaimable,
		ckiClaimable,
		fdgTotalEarned,
		ckiTotalEarned,
		ckiUnused,
		fdgUnused,
		stakeCki,
		stakeCkiState,
		unstakeCki,
		unstakeCkiState,
		mint,
		approveAll,
		stakeCkiClaim,
		stakeCkiClaimState,
		stakeFdg,
		stakeFdgState,
		stakeFdgClaim,
		stakeFdgClaimState,
		unstakeFdg,
		unstakeFdgState,
		gCkiChangeState,
		ckiLockedTotal,
		ckiLockedStake,
		ckiLockedRaw,
		ckiLockRev,
		fdgLockedTotal,
		fdgLockedStake,
		fdgLockedRaw,
		fdgLockRev,

		ckiStakeApy,
		fdgStakeApy,

		ckiLockChangeEvents,
		fdgLockChangeEvents,
		ckiLockClaimEvents,
		fdgLockClaimEvents,
		ckiStakeChangeEvents,
		fdgStakeChangeEvents,
		ckiStakeClaimEvents,
		fdgStakeClaimEvents,
		defenceChangeEvents,
		adGenChangeEvents,
		changeGCkiEvents,

		ckiStakingClaimableRT,
		fdgStakingClaimableRT,
		hasMinted,
		balance,
	} = props;

	const checkConnection = () => {
		if (!account) {
			setModal("generalInfo");
			return false;
		}
		return true;
	};

	const [txInfo, setTxInfo] = useState({
		description: "",
	});

	const [ckiLockYield, setCkiLockYield] = useState(null);
	useEffect(() => {
		if (ckiLockRev && ckiLockedTotal && ckiLockedStake) {
			setCkiLockYield((ckiLockedStake * ckiLockRev) / (BD(ckiLockedTotal) * 24));
		}
	}, [ckiLockedTotal, ckiLockedStake, ckiLockedRaw, ckiLockRev]);

	const [fdgLockYield, setFdgLockYield] = useState(null);
	useEffect(() => {
		if (fdgLockRev && fdgLockedTotal && fdgLockedStake) {
			setFdgLockYield((fdgLockedStake * fdgLockRev) / (BD(fdgLockedTotal) * 24));
		}
	}, [fdgLockedTotal, fdgLockedStake, fdgLockedRaw, fdgLockRev]);

	return (
		<>
			<main className="main">
				<div className="layout">
					<div className="auto__container">
						<div className="layout__inner">
							<div className="layout__inner-main">
								<div className="layoutBox">
									<div className="layoutBox__inner">
										<div className="layoutBox__content">
											<h5>Staking</h5>
											<p>
												Here's a great starting point to convert your tokens
												into the opposite currency!{" "}
												<b>Staking is not vulnerable to attacks</b>.
											</p>
										</div>
										<div className="layoutBox__image">
											<img
												className="swim"
												src={
													process.env.PUBLIC_URL +
													"images/icons/clock.png"
												}
												alt=""
											/>
										</div>
									</div>
								</div>
								<div className="layoutStaking">
									<div className="layoutStaking__item">
										<h4>Cookie</h4>
										<p>Stake CKI to generate FDG tokens.</p>
										<div className="layoutStaking__item-info">
											<h5>
												{printBD(ckiStakeApy)}
												<img
													src={
														process.env.PUBLIC_URL + "/images/fudge.svg"
													}
													alt=""
												/>
												<span>/ H</span>
											</h5>
											<div className="layoutStaking__item-info-row">
												<div className="tooltip">
													<div className="tooltip__icon">{infoIcon}</div>
													<div className="tooltip__content">
														<div className="tooltip__content-inner">
															Hourly yield from your staked CKI.
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="layoutStaking__item-image ">
											<img
												className="swim"
												src={
													process.env.PUBLIC_URL +
													"images/icons/cookie_staking.png"
												}
												alt=""
											/>
										</div>
										<div className="layoutStaking__item-box">
											<div className="layoutStaking__item-box-row">
												<div className="layoutStaking__item-box-row-left">
													<h6 className="big">Staked</h6>
													<div className="tooltip">
														<div className="tooltip__icon">
															{infoIcon}
														</div>
														<div className="tooltip__content">
															<div className="tooltip__content-inner">
																Total amount of CKI staked.
															</div>
														</div>
													</div>
												</div>
												<div className="layoutStaking__item-box-row-right">
													<h6 className="big">{printBD(ckiStaked)}</h6>
													<img
														src={
															process.env.PUBLIC_URL +
															"/images/logo.svg"
														}
														alt=""
													/>
												</div>
											</div>
											<div className="layoutStaking__item-box-row">
												<div className="layoutStaking__item-box-row-left">
													<h6 className="big">Claimable</h6>
													<div className="tooltip">
														<div className="tooltip__icon">
															{infoIcon}
														</div>
														<div className="tooltip__content">
															<div className="tooltip__content-inner">
																FDG tokens awaiting to be claimed
																from CKI staking.
															</div>
														</div>
													</div>
												</div>
												<div className="layoutStaking__item-box-row-right">
													<h6 className="big">
														{printBD(fdgStakingClaimableRT)}
													</h6>
													<img
														src={
															process.env.PUBLIC_URL +
															"/images/fudge.svg"
														}
														alt=""
													/>
												</div>
											</div>
											<div className="layoutStaking__item-box-row">
												<div className="layoutStaking__item-box-row-left">
													<h6 className="big">Total Earned</h6>
													<div className="tooltip">
														<div className="tooltip__icon">
															{infoIcon}
														</div>
														<div className="tooltip__content">
															<div className="tooltip__content-inner">
																Total amount of FDG tokens claimed
																so far from CKI staking.
															</div>
														</div>
													</div>
												</div>
												<div className="layoutStaking__item-box-row-right">
													<h6 className="big">
														{printBD(fdgTotalEarned)}
													</h6>
													<img
														src={
															process.env.PUBLIC_URL +
															"/images/fudge.svg"
														}
														alt=""
													/>
												</div>
											</div>
										</div>
										<div className="layoutStaking__item-btns">
											<button
												className="button secondary"
												onClick={() => {
													soundEffects["increase"].play();
													setModal("stakeCKI");
												}}
											>
												Stake
											</button>
											<button
												className="button primary sm"
												onClick={async () => {
													if (account === undefined) {
														soundEffects["error"].play();
														setAlert("Please connect your wallet.");
														return;
													}

													if (Number(fdgClaimable) === 0) {
														soundEffects["error"].play();
														setAlert(
															"You don't have anything to claim."
														);
														return;
													}

													soundEffects["claim"].play();
													addTx(
														TxIDs.CLAIM_FDG_STAKING,
														"Claiming rewards",
														"Claiming " +
															printBD(fdgClaimable) +
															" FDG",
														""
													);
													await stakeCkiClaim();
												}}
											>
												Claim
											</button>
										</div>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												marginTop: "12px",
												fontSize: "12px",
												cursor: "pointer",
											}}
											className="layoutStaking__item-info-row"
										>
											<p
												onClick={() => {
													soundEffects["decrease"].play();
													setModal("unstakeCKI");
												}}
											>
												Withdraw?
											</p>
										</div>
									</div>
									<div className="layoutStaking__item">
										<h4>Fudge</h4>
										<p>Stake FDG to generate CKI tokens.</p>
										<div className="layoutStaking__item-info">
											<h5>
												{printBD(fdgStakeApy)}
												<img
													src={
														process.env.PUBLIC_URL + "/images/logo.svg"
													}
													alt=""
												/>
												<span>/ H</span>
											</h5>
											<div className="layoutStaking__item-info-row">
												<div className="tooltip">
													<div className="tooltip__icon">{infoIcon}</div>
													<div className="tooltip__content">
														<div className="tooltip__content-inner">
															Hourly yield from your staked FDG.
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="layoutStaking__item-image">
											<img
												className="swim"
												src={
													process.env.PUBLIC_URL +
													"images/icons/fudge_staking.png"
												}
												alt=""
											/>
										</div>
										<div className="layoutStaking__item-box">
											<div className="layoutStaking__item-box-row">
												<div className="layoutStaking__item-box-row-left">
													<h6 className="big">Staked</h6>
													<div className="tooltip">
														<div className="tooltip__icon">
															{infoIcon}
														</div>
														<div className="tooltip__content">
															<div className="tooltip__content-inner">
																Total amount of FDG staked.
															</div>
														</div>
													</div>
												</div>
												<div className="layoutStaking__item-box-row-right">
													<h6 className="big">{printBD(fdgStaked)}</h6>
													<img
														src={
															process.env.PUBLIC_URL +
															"/images/fudge.svg"
														}
														alt=""
													/>
												</div>
											</div>
											<div className="layoutStaking__item-box-row">
												<div className="layoutStaking__item-box-row-left">
													<h6 className="big">Claimable</h6>
													<div className="tooltip">
														<div className="tooltip__icon">
															{infoIcon}
														</div>
														<div className="tooltip__content">
															<div className="tooltip__content-inner">
																CKI tokens awaiting to be claimed
																from FDG staking.
															</div>
														</div>
													</div>
												</div>
												<div className="layoutStaking__item-box-row-right">
													<h6 className="big">
														{printBD(ckiStakingClaimableRT)}
													</h6>
													<img
														src={
															process.env.PUBLIC_URL +
															"/images/logo.svg"
														}
														alt=""
													/>
												</div>
											</div>
											<div className="layoutStaking__item-box-row">
												<div className="layoutStaking__item-box-row-left">
													<h6 className="big">Total Earned</h6>
													<div className="tooltip">
														<div className="tooltip__icon">
															{infoIcon}
														</div>
														<div className="tooltip__content">
															<div className="tooltip__content-inner">
																Total amount of CKI tokens claimed
																so far from FDG staking.
															</div>
														</div>
													</div>
												</div>
												<div className="layoutStaking__item-box-row-right">
													<h6 className="big">
														{printBD(ckiTotalEarned)}
													</h6>
													<img
														src={
															process.env.PUBLIC_URL +
															"/images/logo.svg"
														}
														alt=""
													/>
												</div>
											</div>
										</div>
										<div className="layoutStaking__item-btns">
											<button
												className="button secondary"
												onClick={() => {
													soundEffects["increase"].play();
													setModal("stakeFDG");
												}}
											>
												Stake
											</button>
											<button
												className="button primary sm"
												onClick={async () => {
													if (account === undefined) {
														soundEffects["error"].play();
														setAlert("Please connect your wallet.");
														return;
													}

													if (Number(ckiClaimable) === 0) {
														soundEffects["error"].play();
														setAlert(
															"You don't have anything to claim."
														);
														return;
													}

													soundEffects["claim"].play();
													addTx(
														TxIDs.CLAIM_CKI_STAKING,
														"Claiming rewards",
														"Claiming " +
															printBD(ckiClaimable) +
															" CKI",
														""
													);
													await stakeFdgClaim();
												}}
											>
												Claim
											</button>
										</div>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												marginTop: "12px",
												fontSize: "12px",
												cursor: "pointer",
											}}
											className="layoutStaking__item-info-row"
										>
											<p
												onClick={() => {
													soundEffects["decrease"].play();
													setModal("unstakeFDG");
												}}
											>
												Withdraw?
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="layout__inner-side">
								<Balance
									balFdg={balFdg}
									balCki={balCki}
									cookieList={ckiList}
									fudgeList={fdgList}
									mint={mint}
									approveAll={approveAll}
									addTx={addTx}
									setAlert={setAlert}
									account={account}
									soundEffects={soundEffects}
									balance={balance}
								/>
								<Alerts
									addClass="sm"
									account={account}
									ckiLockChangeEvents={ckiLockChangeEvents}
									fdgLockChangeEvents={fdgLockChangeEvents}
									ckiLockClaimEvents={ckiLockClaimEvents}
									fdgLockClaimEvents={fdgLockClaimEvents}
									ckiStakeChangeEvents={ckiStakeChangeEvents}
									fdgStakeChangeEvents={fdgStakeChangeEvents}
									ckiStakeClaimEvents={ckiStakeClaimEvents}
									fdgStakeClaimEvents={fdgStakeClaimEvents}
									defenceChangeEvents={defenceChangeEvents}
									adGenChangeEvents={adGenChangeEvents}
									changeGCkiEvents={changeGCkiEvents}
									soundEffects={soundEffects}
								/>
							</div>
						</div>
					</div>
				</div>
			</main>
			<AnimatePresence>
				{modal === "stakeCKI" && (
					<Modal setModal={setModal}>
						<StakeCKI
							account={account}
							setAlert={setAlert}
							setModal={setModal}
							addTx={addTx}
							ckiApprove={ckiApprove}
							balCki={balCki}
							ckiInfo={ckiInfo}
							ckiStakeRev={ckiStakeRev}
							ckiTotalStaked={ckiTotalStaked}
							cookieList={ckiList}
							ckiUnused={ckiUnused}
							stakeCki={stakeCki}
							ckiStaked={ckiStaked}
							approveAll={approveAll}
							setTxInfo={setTxInfo}
							lockingApy={fdgLockYield}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "unstakeCKI" && (
					<Modal setModal={setModal}>
						<UnStakeCKI
							account={account}
							setAlert={setAlert}
							setModal={setModal}
							addTx={addTx}
							balCki={balCki}
							ckiStakeRev={ckiStakeRev}
							ckiTotalStaked={ckiTotalStaked}
							cookieList={ckiList}
							ckiStaked={ckiStaked}
							unstakeCki={unstakeCki}
							setTxInfo={setTxInfo}
							lockingApy={fdgLockYield}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "stakeFDG" && (
					<Modal setModal={setModal}>
						<StakeFDG
							account={account}
							setAlert={setAlert}
							setModal={setModal}
							addTx={addTx}
							balFdg={balFdg}
							fdgInfo={fdgInfo}
							fdgStakeRev={fdgStakeRev}
							fdgTotalStaked={fdgTotalStaked}
							fudgeList={fdgList}
							fdgUnused={fdgUnused}
							stakeFdg={stakeFdg}
							fdgStaked={fdgStaked}
							approveAll={approveAll}
							setTxInfo={setTxInfo}
							lockingApy={ckiLockYield}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "unstakeFDG" && (
					<Modal setModal={setModal}>
						<UnStakeFDG
							account={account}
							setAlert={setAlert}
							setModal={setModal}
							balFdg={balFdg}
							addTx={addTx}
							fdgStakeRev={fdgStakeRev}
							fdgTotalStaked={fdgTotalStaked}
							fudgeList={fdgList}
							fdgStaked={fdgStaked}
							unstakeFdg={unstakeFdg}
							setTxInfo={setTxInfo}
							lockingApy={ckiLockYield}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "purchase" && checkConnection() && (
					<Modal setModal={setModal}>
						<Purchase
							setModal={setModal}
							stakeCkiState={stakeCkiState}
							txInfo={txInfo}
							stakeCkiClaimState={stakeCkiClaimState}
							stakeFdgState={stakeFdgState}
							stakeFdgClaimState={stakeFdgClaimState}
							unstakeCkiState={unstakeCkiState}
							unstakeFdgState={unstakeFdgState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "purchase2" && (
					<Modal setModal={setModal}>
						<Purchase2
							setModal={setModal}
							stakeCkiState={stakeCkiState}
							txInfo={txInfo}
							stakeCkiClaimState={stakeCkiClaimState}
							stakeFdgState={stakeFdgState}
							stakeFdgClaimState={stakeFdgClaimState}
							unstakeCkiState={unstakeCkiState}
							unstakeFdgState={unstakeFdgState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "purchaseSuccess" && (
					<Modal setModal={setModal}>
						<PurchaseSuccess
							setModal={setModal}
							stakeCkiState={stakeCkiState}
							txInfo={txInfo}
							stakeCkiClaimState={stakeCkiClaimState}
							stakeFdgState={stakeFdgState}
							stakeFdgClaimState={stakeFdgClaimState}
							unstakeCkiState={unstakeCkiState}
							unstakeFdgState={unstakeFdgState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "purchaseLose" && (
					<Modal setModal={setModal}>
						<PurchaseLose
							setModal={setModal}
							stakeCkiState={stakeCkiState}
							txInfo={txInfo}
							stakeCkiClaimState={stakeCkiClaimState}
							stakeFdgState={stakeFdgState}
							stakeFdgClaimState={stakeFdgClaimState}
							unstakeCkiState={unstakeCkiState}
							unstakeFdgState={unstakeFdgState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "generalInfo" && (
					<Modal setModal={setModal}>
						<GeneralInfo
							setModal={setModal}
							title={"Connect your wallet"}
							content={
								<p>
									Welcome to CryptoCookies ! Remember to connect your wallet by
									clicking the top right button. Get more info
									<Link
										to="https://cryptocookies.gitbook.io/documentation/game-dynamics/getting-started"
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										here.
									</Link>
								</p>
							}
						/>
					</Modal>
				)}
				{modal === "tutorial" && (
					<Modal setModal={setModal}>
						<Tutorial
							setModal={setModal}
							addTx={addTx}
							mint={mint}
							canMint={balCki == 0}
							account={account}
							setAlert={setAlert}
							balCki={balCki}
							balFdg={balFdg}
							soundEffects={soundEffects}
							hasMinted={hasMinted}
							balance={balance}
						/>
					</Modal>
				)}
			</AnimatePresence>
		</>
	);
}
