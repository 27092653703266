import { useEffect, useState } from "react";
import { attackIcon, eyeIcon, infoIcon, refreshIcon } from "../../../../Base/SVG";
import { Addresses } from "index";
import Search from "./components/Search";
import Single from "./components/Single";
import Table from "./components/Table";
import Attacking from "./components/Attacking";
import Locking from "./components/Locking";
import { useSelector } from "react-redux";
import {
	abbreviateNumber,
	BD,
	BN,
	getUnscaledBD,
	inputToBnScaled,
	printBD,
	printUnscaledBD,
} from "interfaces/Numbers";
import { getLeagueBoost } from "interfaces/Utils";
import NoOpponents from "./components/NoOpponents";
import { TxIDs } from "Base/Modals/ModalWrapper";

export const leagueInfo = {
	60: {
		name: "Bronze IV",
		max: 1.15,
	},
	61: {
		name: "Bronze III",
		max: 2.31,
	},
	62: {
		name: "Bronze II",
		max: 4.61,
	},
	63: {
		name: "Bronze I",
		max: 9.22,
	},
	64: {
		name: "Silver IV",
		max: 18.5,
	},
	65: {
		name: "Silver III",
		max: 36.9,
	},
	66: {
		name: "Silver II",
		max: 73.8,
	},
	67: {
		name: "Silver I",
		max: 147.6,
	},
	68: {
		name: "Gold IV",
		max: 295,
	},
	69: {
		name: "Gold III",
		max: 590,
	},
	70: {
		name: "Gold II",
		max: 1180,
	},
	71: {
		name: "Gold I",
		max: 2360,
	},
	72: {
		name: "Platinum III",
		max: 4722,
	},
	73: {
		name: "Platinum II",
		max: 9444,
	},
	74: {
		name: "Platinum I",
		max: 18890,
	},
	75: {
		name: "Diamond III",
		max: 37778,
	},
	76: {
		name: "Diamond II",
		max: 75557,
	},
	77: {
		name: "Diamond I",
		max: 151115,
	},
	78: {
		name: "Master III",
		max: 302230,
	},
	79: {
		name: "Master II",
		max: 604462,
	},
	80: {
		name: "Master I",
		max: 1208925,
	},
	81: {
		name: "Challenger III",
		max: 2417581,
	},
	82: {
		name: "Challenger II",
		max: 4835703,
	},
	83: {
		name: "Challenger I",
		max: 9671406,
	},
};

export function getLeagueName(league) {
	return leagueInfo.hasOwnProperty(league) ? leagueInfo[league].name : "Unranked";
}

export function getLeagueMax(league) {
	return leagueInfo.hasOwnProperty(league) ? leagueInfo[league].max : 1;
}

export default function Compete(props) {
	const {
		addTx,
		setAlert,
		realTimeAdBal,
		setModal,
		soundEffects,
		account,
		signPermit,
		defence,
		adObj,
		adBal,
		createAttack,
		newOpponents,
		ckiLockedRaw,
		ckiLockedStake,
		ckiLockedTotal,
		ckiLockedTotalEarned,
		ckiLock,
		ckiUnlock,
		ckiLockedClaim,
		ckiLockRev,
		fdgLockedRaw,
		fdgLockedStake,
		fdgLockedTotal,
		fdgLockedTotalEarned,
		fdgLock,
		fdgUnlock,
		fdgLockedClaim,
		fdgLockRev,
		setTxInfo,
		ckiLockingClaimableRT,
		fdgLockingClaimableRT,
		hasMinted,
		leagueSize,
		league,
		type,
		opponent,
		opponentsList,
		step,
		setStep,
		setOpponent,
		waitSearchResult,
		setType,
		attackCount,
		waitAttackResult,
	} = props;

	const { dataType } = useSelector((state) => state.common);

	const [tooltipHover, setTooltipHover] = useState(false);
	const [attackDough, setAttackDough] = useState(10);
	const [listView, setListView] = useState(false);

	const [ckiLockYield, setCkiLockYield] = useState(null);
	useEffect(() => {
		if (ckiLockRev && ckiLockedTotal && ckiLockedStake) {
			setCkiLockYield((ckiLockedStake * ckiLockRev) / (BD(ckiLockedTotal) * 24));
		}
	}, [ckiLockedTotal, ckiLockedStake, ckiLockedRaw, ckiLockRev]);

	const [fdgLockYield, setFdgLockYield] = useState(null);
	useEffect(() => {
		if (fdgLockRev && fdgLockedTotal && fdgLockedStake) {
			setFdgLockYield((fdgLockedStake * fdgLockRev) / (BD(fdgLockedTotal) * 24));
		}
	}, [fdgLockedTotal, fdgLockedStake, fdgLockedRaw, fdgLockRev]);

	// console.log(attackCount) TODO(URGENT): NEED ANOTHER WAY TO NONCE!!

	return (
		<>
			<div className="compete">
				<div className="compete__top">
					<div className="compete__top-title">
						{type === "compete" ? <h3>Compete for Yield</h3> : <h3>My Locking</h3>}
						{type === "compete" ? (
							<p>
								Put your cooking skills to the test in the arena. Earn rewards by
								defeating your opponents.
							</p>
						) : (
							<p>
								Lock {dataType == "cookie" ? "CKI " : "FDG "}
								tokens strategically to maximize yield and compete against other
								players in the arena to win even more{" "}
								{dataType == "cookie" ? "CKI " : "FDG "}
								tokens!
							</p>
						)}{" "}
					</div>
					<div className="theme">
						<div className="themeBtn">
							<input
								type="radio"
								name="yield"
								checked={type === "compete"}
								onChange={() => {
									soundEffects["click"].play();
									setType("compete");
								}}
							/>
							<div className="themeBtn__inner">
								<p>Competition</p>
							</div>
						</div>
						<div className="themeBtn">
							<input
								type="radio"
								name="yield"
								checked={type === "lock"}
								onChange={() => {
									setType("lock");
									soundEffects["click"].play();
								}}
							/>
							<div className="themeBtn__inner">
								<p>Locking</p>
							</div>
						</div>
					</div>
				</div>
				<div className="competeBody">
					<div className="competeBody__top">
						<div className="competeBody__slider-outer">
							<div className="competeBody__slider">
								{tooltipHover && (
									<div className="competeBody__slider-tooltip">
										<div className="competeBody__slider-tooltip-inner">
											Higher leagues get a boost on their yield!
										</div>
									</div>
								)}
								<div className="competeBody__slider-item">
									<div className="competeBody__slider-item-image">
										<img
											src={
												process.env.PUBLIC_URL + "/images/icons/trophy.svg"
											}
											alt=""
										/>
									</div>
									<div className="competeBody__slider-item-content">
										<h6> {getLeagueName(league)} </h6>
										<div className="competeBody__slider-item-content-text">
											{leagueSize} Players
										</div>
										<div className="competeBody__slider-item-content-row">
											<p> {"x" + printUnscaledBD(getLeagueBoost(league))} </p>
											<div
												className="tooltip"
												onMouseOver={() => setTooltipHover(true)}
												onMouseOut={() => setTooltipHover(false)}
											>
												<div className="tooltip__icon">{infoIcon}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{type === "compete" && (
								<button
									type="button"
									className="competeBody__slider-view"
									onClick={() => {
										soundEffects["click"].play();
										setModal("leagues");
									}}
								>
									Change League
								</button>
							)}
						</div>
						{step === "choose" && type === "compete" && (
							<button
								type="button"
								className="competeBody__top-view"
								onClick={() => {
									soundEffects["click"].play();
									setListView(!listView);
								}}
							>
								{eyeIcon}
								{listView ? "Single view" : "List view"}{" "}
							</button>
						)}
						{type === "lock" && (
							<div className="competeBody__top-info">
								<h4>
									{" "}
									{printBD(
										dataType == "cookie" ? ckiLockYield : fdgLockYield
									)}{" "}
								</h4>
								<div className="competeBody__top-info-row">
									<h5>
										{" "}
										{dataType === "cookie" ? (
											<img
												src={process.env.PUBLIC_URL + "/images/logo.svg"}
												alt=""
											/>
										) : (
											<img
												src={process.env.PUBLIC_URL + "/images/fudge.svg"}
												alt=""
											/>
										)}
										<span>/ H</span>
									</h5>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												Hourly yield from your locked{" "}
												{dataType == "cookie" ? "CKI" : "FDG"}.
											</div>
										</div>
									</div>
								</div>
							</div>
						)}{" "}
					</div>
					{type === "compete" ? (
						<>
							{" "}
							{step === "search" && <Search setStep={setStep} setModal={setModal} />}
							{step === "choose" && (
								<div className="competeBody__choose">
									{listView ? (
										<Table
											setOpponent={setOpponent}
											account={account}
											setListView={setListView}
											listOpponents={opponentsList}
											dataType={dataType}
											adUsed={attackDough}
										/>
									) : (
										<Single
											setAlert={setAlert}
											newOpponents={newOpponents}
											account={account}
											step={step}
											league={league}
											adObj={adObj}
											setOpponent={setOpponent}
											listOpponents={opponentsList}
											opponent={opponent}
											addTx={addTx}
											setListView={setListView}
											setStep={setStep}
											adUsed={attackDough}
											dataType={dataType}
											signPermit={signPermit}
											setModal={setModal}
											soundEffects={soundEffects}
											waitSearchResult={waitSearchResult}
										/>
									)}
									<div className="competeBody__choose-footer">
										<div className="competeBody__choose-footer-info">
											<p className="sm">Attack Dough</p>
											<div className="tooltip">
												<div className="tooltip__icon">{infoIcon}</div>
												<div className="tooltip__content">
													<div className="tooltip__content-inner">
														The amount of dough you use will define your
														odds of winning!
													</div>
												</div>
											</div>
										</div>
										<div className="competeBody__choose-footer-btns">
											<div className="competeBody__choose-row">
												<div className="competeBody__choose-input">
													<input
														type="number"
														value={attackDough}
														onChange={(e) => {
															if (e.target.value === "") {
																setAttackDough("");
																return;
															}

															const value = Math.floor(
																parseFloat(e.target.value)
															);
															if (
																value <=
																getUnscaledBD(
																	realTimeAdBal
																).toFixed(0)
															) {
																setAttackDough(value);
															}
														}}
														onKeyDown={(e) => {
															if (
																e.key === "." ||
																e.key === "e" ||
																e.key === "-"
															) {
																e.preventDefault();
															}
														}}
													/>
													<p>DGH</p>
												</div>
												<button
													className="button secondary"
													onClick={() => {
														soundEffects["click"].play();
														setAttackDough(
															getUnscaledBD(realTimeAdBal).toFixed(0)
														);
													}}
												>
													Max
												</button>
											</div>
											{listView ? (
												<button
													className="button primary big"
													onClick={async () => {
														if (account === undefined) {
															soundEffects["error"].play();
															setAlert("Please connect your wallet.");
															return;
														}
														soundEffects["search"].play();
														setStep("search");
														await searchOpponents(
															waitSearchResult,
															newOpponents,
															league,
															dataType == "cookie"
																? Addresses.CCLockingCKI
																: Addresses.CCLockingFDG,
															addTx,
															opponent.pid,
															setStep,
															step
														);
													}}
												>
													{refreshIcon}
													New Opponents&nbsp;
													<span
														style={{
															fontSize: "small",
															fontWeight: "normal",
														}}
													>
														{" "}
														(4 DGH)
													</span>
												</button>
											) : (
												<button
													className="button primary big svg24"
													onClick={async () => {
														if (account === undefined) {
															soundEffects["error"].play();
															setAlert("Please connect your wallet.");
															return;
														}

														if (attackDough === "" || attackDough < 2) {
															soundEffects["error"].play();
															setAlert(
																"You need at least 2 DGH to launch an attack"
															);
															return;
														}
														if (opponent.address == account) {
															soundEffects["error"].play();
															setAlert("You cannot attack yourself");
															return;
														}
														if (
															attackDough >
															getUnscaledBD(realTimeAdBal).toFixed(0)
														) {
															soundEffects["error"].play();
															setAlert("You do not have enough DGH");
															return;
														}
														soundEffects["launchAttack"].play();
														const val = inputToBnScaled(attackDough);
														setStep("attack");
														await launchAttack(
															waitAttackResult,
															createAttack,
															opponent,
															val,
															dataType == "cookie"
																? Addresses.CCLockingCKI
																: Addresses.CCLockingFDG,
															league,
															addTx,
															attackCount,
															setStep,
															step,
															account
														);
													}}
												>
													{attackIcon}
													Attack
												</button>
											)}{" "}
										</div>
									</div>
								</div>
							)}
							{step === "attack" && (
								<Attacking attackDough={attackDough} opponent={opponent} />
							)}
							{step === "needOpponents" && (
								<NoOpponents
									account={account}
									setAlert={setAlert}
									league={league}
									adBal={adBal}
									newOpponents={newOpponents}
									addTx={addTx}
									setStep={setStep}
									step={step}
									dataType={dataType}
									soundEffects={soundEffects}
									waitSearchResult={waitSearchResult}
								/>
							)}
							{step === "noOpponents" && (
								<div className="competeBody__noplayers">
									<div className="competeBody__noplayers-title">
										Insufficient Players
									</div>
									<div className="competeBody__noplayers-image">
										<img
											className="swim2"
											src={
												process.env.PUBLIC_URL +
												"/images/noplayers-avatar.png"
											}
											alt=""
										/>
									</div>
									<div className="competeBody__noplayers-content">
										<p>
											Please wait for other players to join or choose another
											league.
										</p>
									</div>
								</div>
							)}{" "}
						</>
					) : (
						<Locking
							soundEffects={soundEffects}
							setModal={setModal}
							addTx={addTx}
							raw={dataType == "cookie" ? ckiLockedRaw : fdgLockedRaw}
							stake={dataType == "cookie" ? ckiLockedStake : fdgLockedStake}
							total={dataType == "cookie" ? ckiLockedTotal : fdgLockedTotal}
							totalEarned={
								dataType == "cookie" ? ckiLockedTotalEarned : fdgLockedTotalEarned
							}
							claimable={
								dataType == "cookie" ? ckiLockingClaimableRT : fdgLockingClaimableRT
							}
							lock={dataType == "cookie" ? ckiLock : fdgLock}
							unlock={dataType == "cookie" ? ckiUnlock : fdgUnlock}
							claim={dataType == "cookie" ? ckiLockedClaim : fdgLockedClaim}
							rev={dataType == "cookie" ? ckiLockRev : fdgLockRev}
							league={league}
							setTxInfo={setTxInfo}
							defence={defence}
							account={account}
							setAlert={setAlert}
							hasMinted={hasMinted}
						/>
					)}{" "}
				</div>
			</div>
		</>
	);
}

export async function searchOpponents(
	waitSearchResult,
	newOpponents,
	league,
	pool,
	addTx,
	oldPid,
	setStep,
	prevStep
) {
	addTx(TxIDs.SEARCH, "League " + getLeagueName(league), "Searching new opponnents", "4 DGH");
	try {
		await newOpponents(pool, BN(league));
		waitSearchResult({ pid: oldPid, searchLeague: league, pool: pool });
	} catch (error) {
		console.error(error);
		setStep(prevStep);
	}
}

export async function launchAttack(
	waitAttackResult,
	createAttack,
	opponent,
	attackDough,
	pool,
	league,
	addTx,
	oldAttackNum,
	setStep,
	prevStep,
	account
) {
	addTx(
		TxIDs.ATTACK,
		"Competition",
		"Attacking " + opponent.address.substr(0, 10) + "...",
		"- " + abbreviateNumber(printBD(attackDough)) + " DGH"
	);
	try {
		await createAttack(
			opponent.id - 1,
			attackDough,
			opponent.address,
			opponent.defence,
			pool,
			true,
			league
		);
		waitAttackResult({
			pid: opponent.pid,
			attacker: account,
			defender: opponent.address,
			attackLeague: league,
			pool: pool,
			attackNum: oldAttackNum,
			display: true,
		});
	} catch (error) {
		console.error(error);
		setStep(prevStep);
	}
}
