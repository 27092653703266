import { useState, useEffect, useRef } from "react";
import { closeIcon, infoIcon } from "../../SVG";
import { useSelector } from "react-redux";
import { getLeagueMax, getLeagueName, leagueInfo } from "Pages/Home/components/Compete/Compete";
import {fetchLeagueSizes} from "Pages/Home/Home";

export default function Leagues({
	setModal,
	targetLeague,
	setTargetLeague,
}) {
	const { dataType } = useSelector((state) => state.common);
	const [leagueSizes, setLeagueSizes] = useState({});
	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {	
		const loadSizes = async () => {
			const sizes = await fetchLeagueSizes(dataType, isMounted);
			if (isMounted.current) {
				// Convert array to object with league numbers as keys
				const sizesMap = {};
				sizes.forEach(item => {
					const league = dataType === "cookie" ? item.cki_league : item.fdg_league;
					const count = dataType === "cookie" ? item.cki_count : item.fdg_count;
					sizesMap[league] = count;
				});
				setLeagueSizes(sizesMap);
			}
		};

		loadSizes();
	}, [dataType]);

	return (
		<div className="modalBoard">
			<div className="modalBoard__inner">
				<div className="modalBoard__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<div className="modalBoard__inner-title">
					<h5>{dataType === "cookie" ? "Cookie " : "Fudge "} Leagues</h5>
				</div>
				<div className="modalBoard__table">
					<div className="modalBoard__table-inner">
						<table>
							<thead>
								<tr>
									<th></th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Name</h6>
										</div>
									</th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Players</h6>
										</div>
									</th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Max win</h6>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{Object.entries(leagueInfo).map(([league, info]) => (
									<TableItem
										key={league}
										league={league}
										info={info}
										playerCount={leagueSizes[league] || 0}
										dataType={dataType}
										onClick={() => {
											setTargetLeague(league);
											setModal(null);
										}}
									/>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div className="modalBoard__table extra">
					<div className="modalBoard__table-inner">
						<table>
							<thead>
								<tr>
									<th></th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Name</h6>
											<div className="tooltip">
												<div className="tooltip__icon">{infoIcon}</div>
											</div>
										</div>
									</th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Players</h6>
										</div>
									</th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Max win</h6>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td></td>
									<td>{getLeagueName(targetLeague)}</td>
									<td>{leagueSizes[targetLeague] || 0}</td>
									<td>
										{(getLeagueMax(targetLeague) * 0.01).toLocaleString("en-US", {
											minimumFractionDigits: 0,
											maximumFractionDigits: 2,
											useGrouping: true,
										})}
										{dataType === "cookie" ? " CKI" : " FDG"}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

const TableItem = ({ league, info, playerCount, dataType, onClick }) => {
	return (
		<tr onClick={onClick}>
			<td></td>
			<td>{info.name}</td>
			<td>{playerCount}</td>
			<td>
				{(info.max * 0.01).toLocaleString("en-US", {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
					useGrouping: true,
				})}
				{dataType === "cookie" ? " CKI" : " FDG"}
			</td>
		</tr>
	);
};