import React, { useEffect, useState } from "react";
import { TxIDs, TxState } from "./ModalWrapper";

const COLORS = {
	SIGNING: "#CC5500",
	SUCCESS: "green",
	FAILURE: "red",
};

export default function ModalSmall(props) {
	const { modal, close, state } = props;
	const [hide, setHide] = useState(false);
	const { id, type, title, amount } = modal;
	const icons = getIcons();

	useEffect(() => {
		if (state === TxState.SUCCESS || state === TxState.FAILURE) {
			setTimeout(() => {
				close(id);
			}, 3000);
		}
	}, [state, modal, close, id]);

	return (
		<>
			<div className={`modalMiniTx ${hide ? "hide" : ""}`}>
				<img className="modalMiniTx-img" loading="lazy" src={icons[id]} alt="" />
				<div className="modalMiniTx__inner">
					<img
						className="img-modalMiniTx__inner"
						loading="lazy"
						src={process.env.PUBLIC_URL + "/images/icons/close-cross.svg"}
						onClick={() => close(id)}
						alt=""
					/>
					<div className="modalMiniTx__inner_type">{type}</div>
					<div className="modalMiniTx__inner_content">
						<div className="modalMiniTx__inner_title">{title}</div>
						{amount !== "" && <div className="modalMiniTx__inner_amount">{amount}</div>}
					</div>
					{state === TxState.SIGNING && (
						<div className="modalMiniTx__inner_loading">
							<img
								className="modalMiniTx__inner_loading-img"
								loading="lazy"
								src={process.env.PUBLIC_URL + "/images/icons/loading-orange.webp"}
								alt=""
							/>
							<div className="modalMiniTx__inner_loading_text signing">
								Sign in wallet
							</div>
						</div>
					)}
					{state === TxState.LOADING && (
						<div className="modalMiniTx__inner_loading">
							<img
								className="modalMiniTx__inner_loading-img"
								loading="lazy"
								src={process.env.PUBLIC_URL + "/images/icons/loading.webp"}
								alt=""
							/>
							<div className="modalMiniTx__inner_loading_text">Loading</div>
						</div>
					)}
					{state === TxState.SUCCESS && (
						<div className="modalMiniTx__inner_loading">
							<div className="modalMiniTx__inner_loading_text success">✓ Success</div>
						</div>
					)}
					{state === TxState.FAILURE && (
						<div className="modalMiniTx__inner_loading">
							<div className="modalMiniTx__inner_loading_text failure">✕ Failed</div>
						</div>
					)}
				</div>
			</div>
			<style>{`
                .modalMiniTx {
                    position: relative;
                    z-index: 999999999;
                    display: flex;
                    font-weight: 400;
                    align-items: center;
                    background-color: white;
                    padding: 12px;
                    flex-grow: 1;
                    border-radius: 28px;
                    min-width: 100%;
                    box-shadow: 0px 4px 8px rgba(45, 35, 25, 0.18);
                    margin-top: 15px;
                    transition: opacity 2s ease-in-out;
                    opacity: 1;
                }

                .modalMiniTx.hide {
                    opacity: 0;
                    visibility: hidden;
                }

                .modalMiniTx-img {
                    aspect-ratio: 1;
                    object-fit: auto;
                    object-position: center;
                    width: 60px;
                    height: 60px;
                    margin-right: 8px;
                }

                .modalMiniTx__inner {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    flex-basis: 0;
                    width: fit-content;
                    margin-right: auto;
                }

                .img-modalMiniTx__inner {
                    aspect-ratio: 0.56;
                    width: 12px;
                    align-self: end;
                    position: absolute;
                    top: 7px;
                    right: 9px;
                }

                .modalMiniTx__inner_type {
                    color: #41496d;
                    font: 14px Montserrat, sans-serif;
                }

                .modalMiniTx__inner_content {
                    justify-content: space-between;
                    align-items: center;
                    display: flex;
                    gap: 10px;
                    font-weight: 700;
                }

                .modalMiniTx__inner_title {
                    color: #687f92;
                    align-self: stretch;
                    margin: auto 0;
                    font: bold 16px Montserrat, sans-serif;
                }

                .modalMiniTx__inner_amount {
                    justify-content: center;
                    border-radius: 100px;
                    background-color: #ff5c33;
                    align-self: stretch;
                    color: rgba(255, 255, 255, 0.9);
                    margin: auto 0;
                    padding: 6px;
                    font: 12px Montserrat, sans-serif;
                }

                .modalMiniTx__inner_loading {
                    align-self: start;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    font-size: 11px;
                    color: #687f92;
                    white-space: nowrap;
                }

                .modalMiniTx__inner_loading-img {
                    aspect-ratio: 1;
                    object-fit: auto;
                    object-position: center;
                    width: 10px;
                    height: 10px;
                    margin-right: 5px;
                    animation: spin 1s linear infinite;
                }

                .modalMiniTx__inner_loading_text {
                    font-family: Montserrat, sans-serif;
                }

                .modalMiniTx__inner_loading_text.signing {
                    color: ${COLORS.SIGNING};
                }

                .modalMiniTx__inner_loading_text.success {
                    color: ${COLORS.SUCCESS};
                }

                .modalMiniTx__inner_loading_text.failure {
                    color: ${COLORS.FAILURE};
                }

                @keyframes spin {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            `}</style>
		</>
	);
}

const getIcons = () => ({
	[TxIDs.ATTACK]: process.env.PUBLIC_URL + "/images/icons/mixing.png",
	[TxIDs.SET_DOUGH]: process.env.PUBLIC_URL + "/images/icons/kitchen/dough.svg",
	[TxIDs.SET_DEFENCE]: process.env.PUBLIC_URL + "/images/icons/kitchen/points.svg",
	[TxIDs.SET_GCKI]: process.env.PUBLIC_URL + "/images/icons/kitchen/goldCookie.svg",
	[TxIDs.LOCK_CKI]: process.env.PUBLIC_URL + "/images/icons/cookie_staking.png",
	[TxIDs.UNLOCK_CKI]: process.env.PUBLIC_URL + "/images/icons/cookie_staking.png",
	[TxIDs.LOCK_FDG]: process.env.PUBLIC_URL + "/images/icons/fudge_staking.png",
	[TxIDs.UNLOCK_FDG]: process.env.PUBLIC_URL + "/images/icons/fudge_staking.png",
	[TxIDs.STAKE_CKI]: process.env.PUBLIC_URL + "/images/icons/cookie_staking.png",
	[TxIDs.UNSTAKE_CKI]: process.env.PUBLIC_URL + "/images/icons/cookie_staking.png",
	[TxIDs.STAKE_FDG]: process.env.PUBLIC_URL + "/images/icons/fudge_staking.png",
	[TxIDs.UNSTAKE_FDG]: process.env.PUBLIC_URL + "/images/icons/fudge_staking.png",
	[TxIDs.CLAIM_ALL]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.CLAIM_FDG_LOCK]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.CLAIM_CKI_LOCK]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.CLAIM_CKI_STAKING]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.CLAIM_FDG_STAKING]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.MINT]: process.env.PUBLIC_URL + "/images/icons/kitchen/goldCookie.svg",
	[TxIDs.SEARCH]: process.env.PUBLIC_URL + "/images/icons/mixing.png",
	[TxIDs.APPROVE]: process.env.PUBLIC_URL + "/images/icons/mixing.png",
});
