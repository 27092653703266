import { useReadContracts } from "wagmi";
import CKI from "../abis/Cki.json";
import FDG from "../abis/Fdg.json";
import { Addresses } from "index";

export function useAllowances(owner: `0x${string}` | undefined) {
	const { data: allowances, refetch } = useReadContracts({
		contracts: [
			{
				abi: CKI,
				address: Addresses.CKI as `0x${string}`,
				functionName: "allowance",
				args: [owner, Addresses.CCStakingCKI],
			},
			{
				abi: CKI,
				address: Addresses.CKI as `0x${string}`,
				functionName: "allowance",
				args: [owner, Addresses.CCLockingCKI],
			},
			{
				abi: CKI,
				address: Addresses.CKI as `0x${string}`,
				functionName: "allowance",
				args: [owner, Addresses.CCCompete],
			},
			{
				abi: CKI,
				address: Addresses.CKI as `0x${string}`,
				functionName: "allowance",
				args: [owner, Addresses.GCKI],
			},
			{
				abi: FDG,
				address: Addresses.FDG as `0x${string}`,
				functionName: "allowance",
				args: [owner, Addresses.CCStakingFDG],
			},
			{
				abi: FDG,
				address: Addresses.FDG as `0x${string}`,
				functionName: "allowance",
				args: [owner, Addresses.CCLockingFDG],
			},
		],
	});

	const [ckiStaking, ckiLocking, ckiCompete, ckiGcki, fdgStaking, fdgLocking] = allowances || [];

	return {
		ckiStaking: (ckiStaking?.result as bigint) ?? 0n,
		ckiLocking: (ckiLocking?.result as bigint) ?? 0n,
		ckiCompete: (ckiCompete?.result as bigint) ?? 0n,
		ckiGcki: (ckiGcki?.result as bigint) ?? 0n,
		fdgStaking: (fdgStaking?.result as bigint) ?? 0n,
		fdgLocking: (fdgLocking?.result as bigint) ?? 0n,
		refetch,
	};
}
