import { useState, useEffect } from "react";
import {
	arrowUpIcon,
	changeIcon,
	roundArrowIcon,
	lockIcon,
	plusIcon2,
	polRight,
	shieldIcon,
	attackIcon,
} from "../../../../Base/SVG";
import { printBD } from "interfaces/Numbers";
import { getLeagueName } from "Pages/Home/components/Compete/Compete";

import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";
import axios from "axios";
import { Addresses } from "index";

function timestampToLocaleTime(timestamp) {
	const date = new Date(timestamp * 1000);
	return date.toLocaleTimeString("en-US", {
		hour: "numeric",
		minute: "2-digit",
		hour12: true,
	});
}

const typeToClass = {
	lock: "orange",
	stake: "green",
	claim: "pink",
	ad: "purple",
	defence: "blue",
	opponents: "violet",
	boost: "yellow",
	attacks: "red",
};

const typeToIcon = {
	lock: lockIcon,
	stake: changeIcon,
	claim: plusIcon2,
	ad: polRight,
	defence: shieldIcon,
	opponents: roundArrowIcon,
	boost: arrowUpIcon,
	attacks: attackIcon,
};

export default function Alerts({ addClass, account, soundEffects }) {
	const [tab, setTab] = useState("events");

	const [changeLockEvents, setChangeLockEvents] = useState([]);
	const [changeStakeEvents, setChangeStakeEvents] = useState([]);
	const [claimEvents, setClaimEvents] = useState([]);
	const [dghGenEvents, setDghGenEvents] = useState([]);
	const [defenceEvents, setDefenceEvents] = useState([]);
	const [boostEvents, setBoostEvents] = useState([]);
	const [opponentsEvents, setOpponentsEvents] = useState([]);
	const [attacksEvents, setAttacksEvents] = useState([]);

	const [personalLockEvents, setPersonalLockEvents] = useState([]);
	const [personalStakeEvents, setPersonalStakeEvents] = useState([]);
	const [personalClaimEvents, setPersonalClaimEvents] = useState([]);
	const [personalDghEvents, setPersonalDghEvents] = useState([]);
	const [personalDefenceEvents, setPersonalDefenceEvents] = useState([]);
	const [personalBoostEvents, setPersonalBoostEvents] = useState([]);
	const [personalOpponentsEvents, setPersonalOpponentsEvents] = useState([]);
	const [personalAttacksEvents, setAttacksOpponentsEvents] = useState([]);
	const allEventArrays = [
		changeLockEvents,
		changeStakeEvents,
		claimEvents,
		dghGenEvents,
		defenceEvents,
		defenceEvents,
		boostEvents,
		opponentsEvents,
		attacksEvents,
	];
	const allPersonalEventArrays = [
		personalLockEvents,
		personalStakeEvents,
		personalClaimEvents,
		personalDghEvents,
		personalDefenceEvents,
		personalBoostEvents,
		personalOpponentsEvents,
		personalAttacksEvents,
	];

	useEffect(() => {
		const fetchEvents = async () => {
			try {
				console.log("Fetching events...");
				const latestPidResponse = await axios.get(
					"https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=latest_transaction"
				);
				const localPIDs = allEventArrays
					.filter((arr) => arr.length > 0)
					.map((arr) => arr[arr.length - 1].pid);
				const localLimit = Math.max(...localPIDs, 0);
				const limit = Math.max(localLimit, latestPidResponse.data[0].latest_pid - 150);

				// Global events
				const [
					lockResponse,
					stakeResponse,
					claimResponse,
					dghResponse,
					defenceResponse,
					boostResponse,
					opponentsResponse,
					attackResponse,
				] = await Promise.all([
					axios.get(
						`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changelock_events&limit=${limit}`
					),
					axios.get(
						`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changestake_events&limit=${limit}`
					),
					axios.get(
						`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=claim_events&limit=${limit}`
					),
					axios.get(
						`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changedghgen_events&limit=${limit}`
					),
					axios.get(
						`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changedefence_events&limit=${limit}`
					),
					axios.get(
						`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changegcki_events&limit=${limit}`
					),
					axios.get(
						`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=newopponents_events&limit=${limit}`
					),
					axios.get(
						`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=attackoutcome_events&limit=${limit}`
					),
				]);

				setChangeLockEvents([
					...lockResponse.data.filter((event) => event.display !== false),
					...changeLockEvents,
				]);
				setChangeStakeEvents([
					...stakeResponse.data.filter(
						(event) =>
							event.pool.toLowerCase() === Addresses.CCStakingCKI.toLowerCase() ||
							event.pool.toLowerCase() === Addresses.CCStakingFDG.toLowerCase()
					),
					...changeStakeEvents,
				]);
				setClaimEvents([...claimResponse.data, ...claimEvents]);
				setDghGenEvents([...dghResponse.data, ...dghGenEvents]);
				setDefenceEvents([...defenceResponse.data, ...defenceEvents]);
				setBoostEvents([...boostResponse.data, ...boostEvents]);

				const attackHashes = new Set(attackResponse.data.map((attack) => attack.txn_hash));
				const filteredOpponents = opponentsResponse.data.filter(
					(opponent) => !attackHashes.has(opponent.txn_hash)
				);

				setOpponentsEvents([...filteredOpponents, ...opponentsEvents]);
				setAttacksEvents([...attackResponse.data, ...attacksEvents]);
			} catch (error) {
				console.error("Error fetching events:", error);
			}
		};

		const fetchPersonalEvents = async () => {
			try {
				// Personal events
				if (account !== undefined) {
					console.log("Fetching personal events...");
					const personalPIDs = allPersonalEventArrays
						.filter((arr) => arr.length > 0)
						.map((arr) => arr[0].pid);
					const personalLimit = Math.max(...personalPIDs, 0);

					const [
						personalLockResponse,
						personalStakeResponse,
						personalClaimResponse,
						personalDghResponse,
						personalDefenceResponse,
						personalBoostResponse,
						personalOpponentsResponse,
						personalAttackResponse,
					] = await Promise.all([
						axios.get(
							`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changelock_by_account&address=${account}&limit=${personalLimit}`
						),
						axios.get(
							`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changestake_by_account&address=${account}&limit=${personalLimit}`
						),
						axios.get(
							`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=claim_by_account&address=${account}&limit=${personalLimit}`
						),
						axios.get(
							`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changedghgen_by_account&address=${account}&limit=${personalLimit}`
						),
						axios.get(
							`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changedefence_by_account&address=${account}&limit=${personalLimit}`
						),
						axios.get(
							`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=changegcki_by_account&address=${account}&limit=${personalLimit}`
						),
						axios.get(
							`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=newopponents_by_account&address=${account}&limit=${personalLimit}`
						),
						axios.get(
							`https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=attackoutcome_by_account&address=${account}&limit=${personalLimit}`
						),
					]);

					setPersonalLockEvents([
						...personalLockResponse.data.filter((event) => event.display !== false),
						...personalLockEvents,
					]);
					setPersonalStakeEvents([
						...personalStakeResponse.data.filter(
							(event) =>
								event.pool.toLowerCase() === Addresses.CCStakingCKI.toLowerCase() ||
								event.pool.toLowerCase() === Addresses.CCStakingFDG.toLowerCase()
						),
						...personalStakeEvents,
					]);
					setPersonalClaimEvents([...personalClaimResponse.data, ...personalClaimEvents]);
					setPersonalDghEvents([...personalDghResponse.data, ...personalDghEvents]);
					setPersonalDefenceEvents([
						...personalDefenceResponse.data,
						...personalDefenceEvents,
					]);
					setPersonalBoostEvents([...personalBoostResponse.data, ...personalBoostEvents]);
					setPersonalOpponentsEvents([
						...personalOpponentsResponse.data,
						...personalOpponentsEvents,
					]);
					setAttacksOpponentsEvents([
						...personalAttackResponse.data,
						...personalAttacksEvents,
					]);
				}
			} catch (error) {
				console.error("Error fetching personal events:", error);
			}
		};

		fetchEvents();
		fetchPersonalEvents();

		const intervalId = setInterval(fetchEvents, 22 * 1000);
		const intervalId2 = setInterval(fetchPersonalEvents, 22 * 1000);
		return () => {
			clearInterval(intervalId);
			clearInterval(intervalId2);
		};
	}, [account]);

	const createAvatarHtml = (address, currentAccount) => {
		const userAvatar = '<img src="./images/icons/metamask.svg" alt="" />';
		const style =
			' style="width: 25px; height: 25px; border-radius: 100%; vertical-align: -7px;background-color: #d0eef4;" title="';

		if (address === currentAccount) {
			return (
				userAvatar.substring(0, 4) +
				style +
				address +
				'"' +
				userAvatar.substring(4, userAvatar.length)
			);
		}

		const avatar = createAvatar(avataaarsNeutral, {
			seed: address,
		}).toString();

		return (
			avatar.substring(0, 4) +
			style +
			'"' +
			avatar.substring(4, avatar.length - 6) +
			"<title>" +
			address +
			"</title></svg>"
		);
	};

	const createItem = (event, type, text, isPersonal = false) => ({
		id: event.block_number,
		time: timestampToLocaleTime(event.block_timestamp),
		type,
		avatar: isPersonal ? "" : createAvatarHtml(event.address, account),
		text,
	});

	const generateList = () => {
		const globalEvents = [
			...changeLockEvents.map((event) =>
				createItem(
					event,
					"lock",
					`Locking ${printBD(event.amount)} ${
						event.pool.toLowerCase() === Addresses.CCLockingCKI.toLowerCase()
							? "CKI"
							: "FDG"
					}`
				)
			),
			...changeStakeEvents.map((event) =>
				createItem(
					event,
					"stake",
					`Staking ${printBD(event.amount)} ${
						event.pool.toLowerCase() === Addresses.CCStakingCKI.toLowerCase()
							? "CKI"
							: "FDG"
					}`
				)
			),
			...claimEvents.map((event) =>
				createItem(
					event,
					"claim",
					`Claimed ${printBD(event.amount)} ${
						event.pool.toLowerCase() === Addresses.CCLockingCKI.toLowerCase() ||
						event.pool.toLowerCase() === Addresses.CCStakingFDG.toLowerCase()
							? "CKI"
							: "FDG"
					}`
				)
			),
			...dghGenEvents.map((event) =>
				createItem(event, "ad", `DGH Gen ${printBD(event.amount)} CKI`)
			),
			...defenceEvents.map((event) =>
				createItem(event, "defence", `Defence ${printBD(event.amount)} CKI`)
			),
			...boostEvents.map((event) =>
				createItem(event, "boost", `Boosted ${printBD(event.cki)} gCKI`)
			),
			...opponentsEvents.map((event) =>
				createItem(event, "opponents", `Opponents ${getLeagueName(event.league)}`)
			),
			...attacksEvents.map((event) => {
				const avatarDefender = `<span style="width: 25px; height: 25px; border-radius: 100%; vertical-align: -7px; background-color: #d0eef4; display: inline-block;">${createAvatarHtml(
					event.defender,
					account
				)}</span>`;

				let result = event.success ? "Won" : "Lost";
				let reward = event.success
					? `${printBD(event.gain)} ${
							event.pool.toLowerCase() === Addresses.CCLockingCKI.toLowerCase()
								? "CKI"
								: "FDG"
					  }`
					: `${printBD(event.apused)} DGH`;

				return {
					id: event.block_number,
					time: timestampToLocaleTime(event.block_timestamp),
					type: "attacks",
					avatar: createAvatarHtml(event.attacker, account),
					text: `${avatarDefender}&nbsp;&nbsp;<text>${result} ${reward}</text>`,
				};
			}),
		];

		const personalEvents = [
			...personalLockEvents.map((event) =>
				createItem(
					event,
					"lock",
					`Locking ${printBD(event.amount)} ${
						event.pool.toLowerCase() === Addresses.CCLockingCKI.toLowerCase()
							? "CKI"
							: "FDG"
					}`,
					true
				)
			),
			...personalStakeEvents.map((event) =>
				createItem(
					event,
					"stake",
					`Staking ${printBD(event.amount)} ${
						event.pool.toLowerCase() === Addresses.CCStakingCKI.toLowerCase()
							? "CKI"
							: "FDG"
					}`,
					true
				)
			),
			...personalClaimEvents.map((event) =>
				createItem(
					event,
					"claim",
					`Claimed ${printBD(event.amount)} ${
						event.pool.toLowerCase() === Addresses.CCLockingCKI.toLowerCase() ||
						event.pool.toLowerCase() === Addresses.CCStakingFDG.toLowerCase()
							? "CKI"
							: "FDG"
					}`,
					true
				)
			),
			...personalDghEvents.map((event) =>
				createItem(event, "ad", `DGH Gen ${printBD(event.amount)} CKI`, true)
			),
			...personalDefenceEvents.map((event) =>
				createItem(event, "defence", `Defence ${printBD(event.amount)} CKI`, true)
			),
			...personalBoostEvents.map((event) =>
				createItem(event, "boost", `Boosted ${printBD(event.cki)} gCKI`, true)
			),
			...personalOpponentsEvents.map((event) =>
				createItem(event, "opponents", `Opponents ${getLeagueName(event.league)}`, true)
			),
			...personalAttacksEvents.map((event) => {
				const amIDefender = event.defender.toLowerCase() === account?.toLowerCase();
				const avatarOther = `<span style="width: 25px; height: 25px; border-radius: 100%; vertical-align: -7px; background-color: #d0eef4; display: inline-block;">${createAvatarHtml(
					amIDefender ? event.attacker : event.defender,
					account
				)}</span>`;

				let prefix = amIDefender ? "Defend" : "Attack";
				let result =
					(event.success && !amIDefender) || (!event.success && amIDefender)
						? "Won"
						: "Lost";
				let reward;
				if (event.success) {
					reward = `${printBD(event.gain)} ${
						event.pool.toLowerCase() === Addresses.CCLockingCKI.toLowerCase()
							? "CKI"
							: "FDG"
					}`;
				} else if (!amIDefender) {
					reward = `${printBD(event.apused)} DGH`;
				} else {
					reward = `${printBD(event.apused / 2)} DGH`;
				}

				return {
					id: event.block_number,
					time: timestampToLocaleTime(event.block_timestamp),
					type: "attacks",
					text: `${avatarOther}&nbsp;&nbsp;<text>${prefix} & ${result} ${reward}</text>`,
				};
			}),
		];

		return {
			globalEvents: globalEvents.sort((a, b) => b.id - a.id),
			personalEvents: personalEvents.sort((a, b) => b.id - a.id),
		};
	};
	const { personalEvents, globalEvents } = generateList();

	return (
		<div className={"alerts " + (addClass ? addClass : "")}>
			<h5>Game Alerts</h5>
			<div className="alerts__tabs">
				<button
					type="button"
					className={"alerts__tab " + (tab === "history" ? "active" : "")}
					onClick={() => {
						soundEffects["click"].play();
						setTab("history");
					}}
				>
					My Action History
				</button>
				<button
					type="button"
					className={"alerts__tab " + (tab === "events" ? "active" : "")}
					onClick={() => {
						soundEffects["click"].play();
						setTab("events");
					}}
				>
					Game Events
				</button>
			</div>
			<div className="alerts__inner">
				{(tab === "history" ? personalEvents : globalEvents).map((item, index) => {
					return (
						<div key={index} className={`alertsItem ${typeToClass[item.type] || ""}`}>
							{item.time && <p className="sm">{item.time}</p>}
							<div className="alertsItem__content">
								<span dangerouslySetInnerHTML={{ __html: item.avatar }}></span>
								<div className="alertsItem__icon">{typeToIcon[item.type]}</div>
								<p
									className="sm"
									dangerouslySetInnerHTML={{ __html: item.text }}
								></p>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
