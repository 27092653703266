import { BD, NULL_ADDRESS, ZERO, getUnscaledBD } from "./Numbers";

export default class Opponent {
	private defence: any;
	private stake: any;
	private ratioVal: any;

	private address: any;
	private id = 1;
	public pid: any;

	public stealable() {
		const stake = this.stake ? BD(this.stake.toString()) : ZERO;
		const ratio = this.ratioVal ? BD(this.ratioVal.toString()) : BD("1");

		return stake.divide(ratio);
	}

	public defenceBal() {
		const ret = this.defence ? BD(this.defence.toString()) : ZERO;
		return ret;
	}

	public winProb(ad: any) {
		if(this.defenceBal() == 0) {
			return 96.4;
		}
		
		ad = Math.max(ad - 2, 0);
		let dp = getUnscaledBD(this.defenceBal());
		let x = ad / (ad + dp);
		let tanh2 = Math.tanh(2);

		const temp = x * 4 - 2;
		x = Math.tanh(temp) + tanh2;
		x = x / 2;
		return isNaN(x) ? 0 : x * 100;
	}

	public toJSON() {
		const j = {
			address: this.address,
			id: this.id,
			defence: this.defenceBal().toString(),
			stealable: this.stealable().toString(),
		};

		return j;
	}

	public constructor(defence?: any, ratioVal?: any, stake?: any, address?: string, id?: any, pid?: any) {
		this.defence = defence;
		this.ratioVal = ratioVal;
		this.stake = stake;
		this.address = address;
		this.id = id;
		this.pid = pid;
	}
}

export function CreateAllOpponents(
	cki: boolean,
	users: any[],
) {
	if (!users || users.length === 0) {
		return Array(8).fill(new Opponent(0, 100, 0, NULL_ADDRESS, 0, -1));
	}

	const opponents: Opponent[] = [];
	let id = 1;
	for (let user of users) {
		opponents.push(new Opponent(user.cki_def, 100, cki ? user.cki_lock : user.fdg_lock, user.address, id, user.pid));
		id++;
	}

	return opponents;
}