import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { infoIcon, refreshIcon, userIcon } from "../../../../../Base/SVG";
import { printBD } from "interfaces/Numbers";

import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";
import { searchOpponents } from "../Compete";
import { Addresses } from "index";

export default function Single({
	account,
	newOpponents,
	setListView,
	setStep,
	listOpponents,
	opponent,
	setOpponent,
	adUsed,
	dataType,
	addTx,
	soundEffects,
	step,
	league,
	waitSearchResult,
	setAlert,
}) {
	const [tooltipHover1, setTooltipHover1] = useState(false);
	const [tooltipHover2, setTooltipHover2] = useState(false);
	const [tooltipHover3, setTooltipHover3] = useState(false);

	const sliderRef = useRef(null);
	const settings = {
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: true,
		afterChange: (index) => {
			setOpponent(listOpponents[index]);
		},
		responsive: [
			{
				breakpoint: 10,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};
	useEffect(() => {
		if (opponent === null) {
			setOpponent(listOpponents[0]);
		} else {
			if (sliderRef.current) {
				const index = listOpponents.map((item) => item.id).indexOf(opponent.id);
				sliderRef.current.slickGoTo(index);
			}
		}
	}, [listOpponents]);

	const avatar = createAvatar(avataaarsNeutral, {
		seed: opponent.address,
	});

	return (
		<div className="competeBody__single">
			<div className="competeBody__single-slider">
				<Slider ref={sliderRef} {...settings}>
					{listOpponents.map((item, index) => {
						return (
							<div className="competeBody__single-slider-item" key={index}>
								{account == opponent.address && (
									<img src="./images/icons/metamask.svg" alt="" />
								)}
								{account != opponent.address && (
									<svg dangerouslySetInnerHTML={{ __html: avatar.toString() }} />
								)}

								{/* // <img src={avatar.toString()} alt="avatar" /> */}
							</div>
						);
					})}
				</Slider>
			</div>
			<div className="competeBody__single-name">
				{opponent.address == account ? "You" : opponent.address.substr(0, 10) + "..."}
			</div>
			<div className="competeBody__single-info">
				<button
					type="button"
					className="competeBody__single-info-btn"
					onClick={() => setListView(true)}
				>
					{userIcon}{" "}
					<p className="sm">
						{listOpponents.map((item) => item.id).indexOf(opponent?.id) + 1} of 8
					</p>
				</button>
				<button
					type="button"
					className="competeBody__single-info-re"
					onClick={async () => {
						if (account === undefined) {
							soundEffects["error"].play();
							setAlert("Please connect your wallet.");
							return;
						}
						soundEffects["search"].play();
						setStep("search");
						await searchOpponents(waitSearchResult, newOpponents, league, dataType == "cookie" ? Addresses.CCLockingCKI : Addresses.CCLockingFDG, addTx, opponent.pid, setStep, step);
					}}
				>
					{refreshIcon}
				</button>
			</div>
			<div className="competeBody__single-divider">
				<span>Battle Stats</span>
			</div>
			<div className="competeBody__single-cards">
				<div className="competeBody__single-card blue">
					{tooltipHover1 && (
						<div className="competeBody__single-card-tooltip">
							<div className="competeBody__single-card-tooltip-inner">
								The more defence an opponent has, the harder it is to attack them
								successfully.
							</div>
						</div>
					)}
					<div className="competeBody__single-card-inner">
						<div className="competeBody__single-card-image">
							<img
								className="swim2"
								src={process.env.PUBLIC_URL + "/images/icons/defence.png"}
								alt=""
							/>
						</div>
						<div className="competeBody__single-card-title">
							<h6 className="sm">Enemy Defence</h6>
							<div
								className="tooltip"
								onMouseOver={() => setTooltipHover1(true)}
								onMouseOut={() => setTooltipHover1(false)}
							>
								<div className="tooltip__icon">{infoIcon}</div>
							</div>
						</div>
						<h5 className="sm">{printBD(opponent?.defenceBal())} DP</h5>
					</div>
				</div>
				<div
					className={
						"competeBody__single-card " +
						(Math.max(opponent?.winProb(adUsed).toFixed(2), 0) < 50 ? "red" : "green")
					}
				>
					{tooltipHover2 && (
						<div className="competeBody__single-card-tooltip">
							<div className="competeBody__single-card-tooltip-inner">
								Increase the dough used for the attack to increase your victory
								chances!
							</div>
						</div>
					)}
					<div className="competeBody__single-card-inner">
						<div className="competeBody__single-card-image">
							<img
								className="swim2"
								src={process.env.PUBLIC_URL + "/images/icons/flag.png"}
								alt=""
							/>
						</div>
						<div className="competeBody__single-card-title">
							<h6 className="sm">Victory Chance</h6>
							<div
								className="tooltip"
								onMouseOver={() => setTooltipHover2(true)}
								onMouseOut={() => setTooltipHover2(false)}
							>
								<div className="tooltip__icon">{infoIcon}</div>
							</div>
						</div>
						<h5 className="sm">
							{Math.max(opponent?.winProb(adUsed).toFixed(2), 0)} %
						</h5>
					</div>
				</div>
				<div className="competeBody__single-card yellow">
					{tooltipHover3 && (
						<div className="competeBody__single-card-tooltip">
							<div className="competeBody__single-card-tooltip-inner">
								Reward you will capture from your opponent if the attack is
								successful.
							</div>
						</div>
					)}
					<div className="competeBody__single-card-inner">
						<div className="competeBody__single-card-image">
							<img
								className="swim2"
								src={process.env.PUBLIC_URL + "/images/icons/coin.png"}
								alt=""
							/>
						</div>
						<div className="competeBody__single-card-title">
							<h6 className="sm">Bounty Reward</h6>
							<div
								className="tooltip"
								onMouseOver={() => setTooltipHover3(true)}
								onMouseOut={() => setTooltipHover3(false)}
							>
								<div className="tooltip__icon">{infoIcon}</div>
							</div>
						</div>
						<h5 className="sm">
							{printBD(opponent?.stealable())} {dataType == "cookie" ? "CKI" : "FDG"}
						</h5>
					</div>
				</div>
			</div>
		</div>
	);
}
