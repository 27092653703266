import GCKI from "../abis/GCki.json";
import { useReadContracts, useWriteContract } from "wagmi";
import { Chain } from "viem";
import { useMemo, useCallback } from "react";
import { BD, ZERO } from "./Numbers";

type EscrowState = [number, bigint];

const REFETCH_INTERVAL = 30000;

export function useGCki(
	address: `0x${string}`,
	account: `0x${string}` | undefined,
	chain: Chain
) {
	const { data: contractReads, refetch } = useReadContracts({
		contracts: [
			{
				abi: GCKI,
				address,
				functionName: "balanceOf",
				args: [account],
			},
			{
				abi: GCKI,
				address,
				functionName: "usersEscrow",
				args: [account],
			},
		],
		query: {
			refetchIntervalInBackground: true,
			refetchInterval: REFETCH_INTERVAL,
		},
	});

	const [balanceResult, escrowResult] = contractReads || [];
	const balanceValue = balanceResult?.result as bigint;
	const escrowValue = escrowResult?.result as EscrowState;

	const { writeContractAsync: sendWithdraw, status: withdrawState } = useWriteContract();

	const end = useMemo(
		() => (escrowValue?.[0] ? BD(escrowValue[0].toString()) : ZERO),
		[escrowValue]
	);

	const deposit = useMemo(
		() => (escrowValue?.[1] ? BD(escrowValue[1].toString()) : ZERO),
		[escrowValue]
	);

	const withdraw = useCallback(
		() =>
			sendWithdraw({
				abi: GCKI,
				address,
				functionName: "withdraw",
				args: [],
				chain,
				account,
			}),
		[sendWithdraw, address, chain, account]
	);

	return {
		// Raw states
		balance: balanceValue || 0n,
		// Computed values
		deposit,
		end,
		// Transaction state
		withdrawState,
		// Actions
		withdraw,
		// Refetch
		refetch,
	};
}