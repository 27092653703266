import { useEffect, useState } from "react";
import { closeIcon, infoIcon } from "../../SVG";
import DoghChart from "../../DoghChart";
import { useSelector } from "react-redux";
import { BD, BN, getUnscaledBD, inputToBnScaled, printBD, ZERO_BYTES_32 } from "interfaces/Numbers";
import { computeRatio } from "App";
import { Addresses } from "index";
import { getLockStrength } from "interfaces/Utils";
import { GetVulnerability } from "./SetDefence";
import { TxIDs } from "../ModalWrapper";

export default function Lock(props) {
	const [balance, setBalance] = useState(1000);
	const [value, setValue] = useState(0);
	const { dataType } = useSelector((state) => state.common);

	const {
		account,
		setAlert,
		locking,
		setModal,
		totalBal,
		tokenInfo,
		addTx,
		unused,
		isLock,
		raw,
		stake,
		total,
		defence,
		lock,
		unlock,
		rev,
		list,
		approveAll,
		stakeApy,
		gCkiBal,
		soundEffects,
	} = props;

	const [updatedList, setUpdatedList] = useState([...list]);
	const [lockApy, setLockApy] = useState(0);
	const [vulnerability, setVulnerability] = useState(GetVulnerability(defence, raw));

	useEffect(() => {
		setBalance(Math.floor(getUnscaledBD(isLock ? unused : raw) * 100) / 100);
	}, [unused, raw, isLock]);

	useEffect(() => {
		if (
			typeof value === "string" &&
			((value.charAt(value.length - 1) != "." &&
				value.charAt(value.length - 2) != "." &&
				value.charAt(value.length - 3) != ".") ||
				value[0] == "-" ||
				value[0] == "+")
		) {
			setValue(Math.abs(Math.floor(parseFloat(value.toString()) * 100) / 100));
		}
	}, [value]);

	useEffect(() => {
		setUpdatedList([...list]);
		if (value !== "" && !isNaN(Number(value)) && !isNaN(Number(unused))) {
			const tot = list.reduce((sum, item) => BD(sum).add(BD(item.bal)), BD(0));
			const newValue = BD(value)
				.multiply(BD(10 ** 18))
				.toBigInteger();
			const newList = list.map((obj) => ({ ...obj }));
			newList[0].percent = computeRatio(
				isLock
					? BD(BN(list[0].bal).sub(BN(newValue)))
					: BD(BN(list[0].bal).add(BN(newValue))),
				tot
			);
			newList[2].percent = computeRatio(
				isLock
					? BD(BN(list[2].bal).add(BN(newValue)))
					: BD(BN(list[2].bal).sub(BN(newValue))),
				tot
			);

			const newLocked = isLock ? BN(raw).add(BN(newValue)) : BN(raw).sub(BN(newValue));
			setVulnerability(GetVulnerability(defence, newLocked));
			setUpdatedList(newList);
		} else {
			setVulnerability(GetVulnerability(defence, raw));
		}
	}, [value, list, unused, raw, stake, isLock, defence]);

	useEffect(() => {
		if (value !== "" && !isNaN(Number(value)) && !isNaN(Number(unused))) {
			const newValue = BD(value).multiply(BD(10 ** 18));
			if (isLock) {
				const newNetStake = getLockStrength(
					BN(raw).add(BN(newValue.toBigInteger())),
					BN(gCkiBal)
				);
				const toAdd = BD(Number(newNetStake) - Number(stake));
				const demon = BD(total).add(toAdd) * 24;
				setLockApy(demon === 0 ? 0 : (rev * BD(newNetStake)) / demon);
			} else {
				const newNetStake = getLockStrength(
					BN(raw).sub(BN(newValue.toBigInteger())),
					BN(gCkiBal)
				);
				const toSub = BD(Number(stake) - Number(newNetStake));
				const denom = BD(total).subtract(toSub) * 24;
				setLockApy(denom === 0 ? 0 : (rev * BD(newNetStake)) / denom);
			}
		} else {
			const netStake = getLockStrength(BN(raw), gCkiBal);
			const denom = BD(total) * 24;
			setLockApy(denom === 0 ? 0 : (rev * BD(netStake)) / denom);
		}
	}, [value, raw, stake, isLock, rev, total, gCkiBal]);

	return (
		<div className="modalStake">
			<div className="modalStake__inner">
				<div className="modalStake__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<div className="modalStake__inner-title">
					<h4>
						{locking ? "Lock" : "Unlock "} your {dataType === "cookie" ? "CKI" : "FDG"}
					</h4>
					<div className="modalStake__inner-title-row">
						<img
							src={process.env.PUBLIC_URL + "images/icons/calendarSmall.svg"}
							alt=""
						/>
						<p>Beta Season</p>
					</div>
				</div>
				<div className="modalStake__inner-desc">
					<p>
						{locking
							? "Lock " +
							  (dataType === "cookie" ? "CKI" : "FDG") +
							  " to earn " +
							  (dataType === "cookie" ? "CKI" : "FDG") +
							  " tokens. Choose the percentage of unused tokens that you want to lock."
							: "Withdraw locked " +
							  (dataType === "cookie" ? "CKI" : "FDG") +
							  " without any constraint or fee."}
					</p>

					{locking && defence == 0 ? (
						<div className="warning-box">
							<p>
								<b>Careful:</b> You might want to increase your defense before
								locking!
							</p>
						</div>
					) : null}
				</div>

				<div className="modalStake__content">
					<div className="modalStake__left">
						<div className="modalStake__left-func mb19">
							<div className="modalStake__left-title">
								<p className="sm">
									{" "}
									{locking ? "Available Balance:" : "Current Lock:"}{" "}
								</p>
								<div className="modalStake__left-title-row">
									<p className="sm">{printBD(isLock ? unused : raw)}</p>
									{dataType == "cookie" ? (
										<img
											src={process.env.PUBLIC_URL + "images/logo.svg"}
											alt=""
										/>
									) : (
										<img
											src={process.env.PUBLIC_URL + "images/fudge.svg"}
											alt=""
										/>
									)}
								</div>
							</div>
							<div className="modalStake__left-row">
								<div className="modalStake__left-input">
									<input
										type="text"
										value={!isNaN(value) ? value : ""}
										onChange={(e) => {
											if (
												e.nativeEvent.data !== " " &&
												e.target.value <= balance
											) {
												setValue(e.target.value);
											}
										}}
									/>
									<p>{dataType === "cookie" ? "CKI" : "FDG"}</p>
								</div>
								<button
									className="button secondary"
									onClick={() => {
										soundEffects["click"].play();
										setValue(balance);
									}}
								>
									Max
								</button>
							</div>
							<div className="modalStake__range">
								<div className="modalStake__range-line">
									<span style={{ width: `${(value * 100) / balance}%` }}></span>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 0 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(balance * 0)}
									></button>
									<p>0%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 24 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.25 * 100) / 100)
										}
									></button>
									<p>25%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 49 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.5 * 100) / 100)
										}
									></button>
									<p>50%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 74 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.75 * 100) / 100)
										}
									></button>
									<p>75%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 99 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(balance * 1)}
									></button>
									<p>100%</p>
								</div>
							</div>
						</div>
						<div className="modalStake__left-box mb16">
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p style={{ fontWeight: "bold" }}>Total Yield</p>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												Combined hourly yield from staking and locking.
											</div>
										</div>
									</div>
								</div>
								<div className="modalStake__left-box-row-right">
									<div style={{ display: "flex", alignItems: "center" }}>
										<p style={{ color: "#18be28", fontWeight: "bold" }}>
											{printBD(
												(isNaN(lockApy) ? 0 : lockApy) +
													(isNaN(stakeApy) ? 0 : stakeApy)
											)}{" "}
										</p>
										<img
											src={
												process.env.PUBLIC_URL +
												(dataType === "cookie"
													? "images/logo.svg"
													: "images/fudge.svg")
											}
											alt=""
											style={{ marginLeft: "5px", marginRight: "5px" }} // Adjust margin as needed
										/>
										<p style={{ fontWeight: "bold" }}>/H</p>
									</div>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>From Locking</p>
								</div>
								<div className="modalStake__left-box-row-right">
									<p>{printBD(lockApy)}</p>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>From Staking</p>
								</div>
								<div className="modalStake__left-box-row-right">
									<p>{printBD(stakeApy)}</p>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>Vulnerability</p>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												Vulnerability of your locked{" "}
												{dataType === "cookie" ? "CKI" : "FDG"} tokens in
												the face of attacks.
											</div>
										</div>
									</div>
								</div>
								<div className="modalStake__left-box-row-right">
									<p style={{ color: vulnerability.color }}>
										{vulnerability.vulnerability}
									</p>
									<img
										src={process.env.PUBLIC_URL + "images/icons/shield.svg"}
										alt=""
									/>
								</div>
							</div>
						</div>
						<div
							className="button primary sm"
							onClick={async () => {
								if (account === undefined) {
									soundEffects["error"].play();
									setAlert("Please connect your wallet.");
									return;
								}

								if (value === undefined || isNaN(value)) {
									soundEffects["error"].play();
									setAlert("Please enter a valid number.");
									return;
								}

								if (value === 0) {
									soundEffects["error"].play();
									setAlert("Please enter a non-zero amount.");
									return;
								}

								const val = inputToBnScaled(value);
								if (isLock) {
									soundEffects["coin"].play();
									try {
										setModal(null);
										approveAll(val);
										addTx(
											dataType == "cookie" ? TxIDs.LOCK_CKI : TxIDs.LOCK_FDG,
											"Adding lock",
											"Locking " +
												value.toLocaleString("en-US") +
												" " +
												(dataType == "cookie" ? "CKI" : "FDG"),
											""
										);
										await lock(val, 0, 0, ZERO_BYTES_32, ZERO_BYTES_32);
									} catch (e) {
										console.log("error while locking: ", e);
									}
								} else {
									soundEffects["decrease"].play();
									setModal(null);
									addTx(
										dataType == "cookie" ? TxIDs.UNLOCK_CKI : TxIDs.UNLOCK_FDG,
										"Withdrawal",
										"Unlocking " +
											value.toLocaleString("en-US") +
											" " +
											(dataType == "cookie" ? "CKI" : "FDG"),
										""
									);
									try {
										await unlock(val);
									} catch (e) {
										console.log("error while unlocking: ", e);
									}
								}
							}}
						>
							{isLock ? "Lock" : "Unlock"}
						</div>
					</div>
					<div className="modalStake__right">
						<div className="modalStake__right-title">
							<h5 className="sm">{dataType === "cookie" ? "CKI" : "FDG"} Balance</h5>
							<div className="tooltip">
								<div className="tooltip__icon">{infoIcon}</div>
								<div className="tooltip__content">
									<div className="tooltip__content-inner">
										Current {dataType === "cookie" ? "CKI" : "FDG"} balance and
										distribution within the game.
									</div>
								</div>
							</div>
						</div>
						<div className="modalStake__right-inner">
							<div className="modalStake__right-chart">
								<div className="modalStake__right-chart-bal">
									{dataType === "cookie" ? (
										<img src="./images/logo.svg" alt="" />
									) : (
										<img src="./images/fudge.svg" alt="" />
									)}
									{printBD(totalBal)}
								</div>
								<DoghChart
									data={updatedList.map((item) => item.percent)}
									bgColors={updatedList.map((item) => item.color)}
									percentCircle="75%"
								/>
							</div>
							<div className="modalStake__right-data">
								{updatedList.map((item, index) => {
									return (
										<div className="modalStake__right-data-item" key={index}>
											<div
												className="modalStake__right-data-item-icon"
												style={{ background: item.color }}
											></div>
											{item.title} <span>{item.percent}%</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
