import { useSelector } from "react-redux";
import { infoIcon, lockIcon } from "../../../../../Base/SVG";
import { printBD } from "interfaces/Numbers";
import { TxIDs } from "Base/Modals/ModalWrapper";

export default function Locking(props) {
	const { setModal, raw, totalEarned, claimable, soundEffects, claim, addTx, defence, account, setAlert, hasMinted } =
		props;


	const { dataType } = useSelector((state) => state.common);
	return (
		<div className="competeBody__lock">
			<div className="competeBody__lock-image">
				{dataType === "cookie" ? (
					<img
						className="swim"
						src={process.env.PUBLIC_URL + "/images/icons/lock_cookie.png"}
						alt=""
					/>
				) : (
					<img
						className="swim"
						src={process.env.PUBLIC_URL + "/images/icons/lock_fdg.png"}
						alt=""
					/>
				)}
			</div>
			<div className="competeBody__lock-box">
				<div className="competeBody__lock-row">
					<div className="competeBody__lock-row-left">
						<h6 className="big">Locked</h6>
						<div className="tooltip">
							<div className="tooltip__icon">{infoIcon}</div>
							<div className="tooltip__content">
								<div className="tooltip__content-inner">
									Total amount of {dataType === "cookie" ? "CKI" : "FDG"} locked.
								</div>
							</div>
						</div>
					</div>
					<div className="competeBody__lock-row-right">
						<h6 className="big">{printBD(raw)}</h6>
						{dataType === "cookie" ? (
							<img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" />
						) : (
							<img src={process.env.PUBLIC_URL + "/images/fudge.svg"} alt="" />
						)}
					</div>
				</div>
				<div className="competeBody__lock-row">
					<div className="competeBody__lock-row-left">
						<h6 className="big">Claimable</h6>
						<div className="tooltip">
							<div className="tooltip__icon">{infoIcon}</div>
							<div className="tooltip__content">
								<div className="tooltip__content-inner">
									FDG tokens awaiting to be claimed from{" "}
									{dataType === "cookie" ? "CKI" : "FDG"} staking.
								</div>
							</div>
						</div>
					</div>
					<div className="competeBody__lock-row-right">
						<h6 className="big">{printBD(claimable)}</h6>
						{dataType === "cookie" ? (
							<img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" />
						) : (
							<img src={process.env.PUBLIC_URL + "/images/fudge.svg"} alt="" />
						)}
					</div>
				</div>
				<div className="competeBody__lock-row">
					<div className="competeBody__lock-row-left">
						<h6 className="big">Total Earned</h6>
						<div className="tooltip">
							<div className="tooltip__icon">{infoIcon}</div>
							<div className="tooltip__content">
								<div className="tooltip__content-inner">
									Total amount of {dataType === "cookie" ? "CKI" : "FDG"} tokens
									claimed so far from {dataType === "cookie" ? "CKI" : "FDG"}{" "}
									locking
								</div>
							</div>
						</div>
					</div>
					<div className="competeBody__lock-row-right">
						<h6 className="big">{printBD(totalEarned)}</h6>
						{dataType === "cookie" ? (
							<img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" />
						) : (
							<img src={process.env.PUBLIC_URL + "/images/fudge.svg"} alt="" />
						)}
					</div>
				</div>
			</div>
			<div className="competeBody__lock-btns">
				<button
					className="button secondary"
					onClick={() => {
						soundEffects["increase"].play();
						setModal("lock");
					}}
				>
					{lockIcon}Lock
				</button>
				<button
					className={`button primary sm`}
					onClick={async () => {
						if (account === undefined) {
							soundEffects["error"].play();
							setAlert("Please connect your wallet.");
							return;
						}

						if (Number(claimable) === 0) {
							soundEffects["error"].play();
							setAlert("You don't have anything to claim.");
							return;
						}			
						
						soundEffects["claim"].play();
						addTx(
							dataType === "cookie" ? TxIDs.CLAIM_CKI_LOCK : TxIDs.CLAIM_FDG_LOCK,
							"Claiming rewards",
							"Claiming " +
								printBD(claimable) +
								" " +
								(dataType === "cookie" ? "CKI" : "FDG"),
							""
						);
						try {
							await claim();
						}
						catch (error) {
							console.error("Claim error:", error);
						}
					}}
				>
					Claim
				</button>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					marginTop: "12px",
					fontSize: "12px",
					cursor: "pointer",
				}}
				className="competeBody__lock-row-left"
			>
				<p
					onClick={() => {
						soundEffects["decrease"].play();
						setModal("unlock");
					}}
				>
					Withdraw?
				</p>
			</div>
		</div>
	);
}
