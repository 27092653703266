import CCCompete from "../abis/CCCompete.json";
import { useReadContracts, useWriteContract } from "wagmi";
import { Chain } from "viem";
import { useMemo } from "react";
import { BD, ZERO } from "./Numbers";

type AttackUserState = [bigint, bigint, bigint];

const REFETCH_INTERVAL = 30000;

export function useCompete(
	address: `0x${string}`,
	account: `0x${string}` | undefined,
	chain: Chain
) {
	const { data: contractReads, refetch } = useReadContracts({
		contracts: [
			{
				abi: CCCompete,
				address,
				functionName: "defenseBalances",
				args: [account],
			},
			{
				abi: CCCompete,
				address,
				functionName: "attackUserStates",
				args: [account],
			},
			{
				abi: CCCompete,
				address,
				functionName: "dghBalanceOf",
				args: [account],
			},
		],
		query: {
			refetchIntervalInBackground: true,
			refetchInterval: REFETCH_INTERVAL,
		},
	});

	const [defenseResult, userStateResult, apResult] = contractReads || [];
	const defense = defenseResult?.result as [bigint];
	const userState = userStateResult?.result as AttackUserState;
	const ap = apResult?.result as bigint;

	const { writeContractAsync: sendSetDefense, status: defenseState } = useWriteContract();
	const { writeContractAsync: sendSetApGen, status: apGenState } = useWriteContract();
	const { writeContractAsync: sendDrawOpponents, status: searchState } = useWriteContract();
	const { writeContractAsync: sendCreateAttack, status: attackState } = useWriteContract();

	const defenseBal = useMemo(
		() => (defense ? BD(defense.toString()) : ZERO),
		[defense]
	);

	const ckiBlockedAp = useMemo(
		() => (userState?.[0] ? BD(userState[0].toString()) : ZERO),
		[userState]
	);

	const apPerDay = useMemo(
		() => (ap ? BD(ckiBlockedAp).multiply(BD(24)) : ZERO),
		[ap, ckiBlockedAp]
	);

	const setDefense = useMemo(
		() => (amount: bigint, deadline: bigint, v: bigint, r: string, s: string) =>
			sendSetDefense({
				abi: CCCompete,
				address,
				functionName: "setDefensePoints",
				args: [amount, deadline, v, r, s],
				chain,
				account,
			}),
		[sendSetDefense, address, chain, account]
	);

	const setApGen = useMemo(
		() => (amount: bigint, deadline: bigint, v: bigint, r: string, s: string) =>
			sendSetApGen({
				abi: CCCompete,
				address,
				functionName: "setDghGeneration",
				args: [amount, deadline, v, r, s],
				chain,
				account,
			}),
		[sendSetApGen, address, chain, account]
	);

	const drawOpponents = useMemo(
		() => (pool: string, league: bigint) =>
			sendDrawOpponents({
				abi: CCCompete,
				address,
				functionName: "getNewSeed",
				args: [pool, league],
				chain,
				account,
			}),
		[sendDrawOpponents, address, chain, account]
	);

	const createAttack = useMemo(
		() => (
			index: bigint,
			ap: bigint,
			opponent: string,
			dpTarget: bigint,
			pool: string,
			redraw: boolean,
			league: bigint
		) =>
			sendCreateAttack({
				abi: CCCompete,
				address,
				functionName: "createAttack",
				args: [index, ap, opponent, dpTarget, pool, redraw, league],
				chain,
				account,
			}),
		[sendCreateAttack, address, chain, account]
	);

	return {
		// Raw states
		defenseBal,
		ckiBlockedAp,
		apPerDay,
		userState,
		ap,
		// Transaction states
		defenseState,
		apGenState,
		searchState,
		attackState,
		// Actions
		setDefense,
		setApGen,
		drawOpponents,
		createAttack,
		// Attack count
		attackCount: userState?.[2],
		// Refetch
		refetch,
	};
}