import RealApp from "./RealApp";
import { Addresses } from "./";
import { useERC20 } from "./interfaces/Erc20";
import { useGCki } from "./interfaces/GCki";
import { useStakingPool } from "./interfaces/Staking";
import { useLockingPool } from "./interfaces/Locking";
import { useCompete } from "./interfaces/Compete";
import { BD, ZERO } from "./interfaces/Numbers";
import { useUtils } from "interfaces/Utils";
import { useEffect, useState, useMemo } from "react";
import { useDistribution } from "interfaces/CCDistr";
import { useAccount, useBalance } from "wagmi";
import { slice, hexToNumber } from "viem";

var bigdecimal = require("bigdecimal");

export function computeRatio(a, b) {
	return Number(
		(b.compareTo(ZERO) !== 0
			? a
					.divide(b, 4, bigdecimal.RoundingMode.HALF_UP())
					.multiply(BD(100))
					.setScale(2, bigdecimal.RoundingMode.HALF_UP())
			: BD(0)
		).toString()
	);
}

function App() {
	const { address, chain } = useAccount();

	const account = address;
	const etherBalance = useBalance({ address: account });

	const cki = useERC20(Addresses.CKI, account);
	const fdg = useERC20(Addresses.FDG, account);
	const dgh = useERC20(Addresses.DGH, account);
	const gCki = useGCki(Addresses.GCKI, account);

	const utils = useUtils(Addresses.CkiDistr, Addresses.FdgDistr, Addresses.Utils);

	const stakingCki = useStakingPool(Addresses.CCStakingCKI, account, utils.ckiStakeRev);
	const lockingCki = useLockingPool(Addresses.CCLockingCKI, account, utils.ckiLockRev);

	const stakingFdg = useStakingPool(Addresses.CCStakingFDG, account, utils.fdgStakeRev);
	const lockingFdg = useLockingPool(Addresses.CCLockingFDG, account, utils.fdgLockRev);

	const ccDistr = useDistribution(Addresses.CCDistr, account, chain);
	const compete = useCompete(Addresses.CCCompete, account);

	const totalCki = useMemo(() => {
		const ckiBalance = cki.balance ? BD(cki.balance.toString()) : ZERO;
		const gCkiDeposit = gCki.deposit || ZERO;
		const stakingBalance = stakingCki.ownStake || ZERO;
		const blockedAp = compete.ckiBlockedAp || ZERO;
		const defenseBalance = compete.defenseBal || ZERO;
		const lockingBalance = lockingCki.rawStake || ZERO;

		return ckiBalance
			.add(gCkiDeposit)
			.add(stakingBalance)
			.add(blockedAp)
			.add(defenseBalance)
			.add(lockingBalance);
	}, [
		cki.balance,
		gCki.deposit,
		stakingCki.ownStake,
		compete.ckiBlockedAp,
		compete.defenseBal,
		lockingCki.rawStake,
	]);

	const totalFdg = useMemo(() => {
		const fdgBalance = fdg.balance ? BD(fdg.balance.toString()) : ZERO;
		const stakingBalance = stakingFdg.ownStake || ZERO;
		const lockingBalance = lockingFdg.rawStake || ZERO;

		return fdgBalance.add(stakingBalance).add(lockingBalance);
	}, [fdg.balance, stakingFdg.ownStake, lockingFdg.rawStake]);

	// Token lists with proper balance handling
	const fdgList = useMemo(
		() => [
			{
				id: "1",
				title: "Unused",
				bal: fdg.balance ? BD(fdg.balance.toString()) : ZERO,
				percent: computeRatio(fdg.balance ? BD(fdg.balance.toString()) : ZERO, totalFdg),
				color: "#C0C0C0",
			},
			{
				id: "2",
				title: "Staked",
				bal: stakingFdg.ownStake || ZERO,
				percent: computeRatio(stakingFdg.ownStake || ZERO, totalFdg),
				color: "#3065EC",
			},
			{
				id: "3",
				title: "Locked",
				bal: lockingFdg.rawStake || ZERO,
				percent: computeRatio(lockingFdg.rawStake || ZERO, totalFdg),
				color: "#EC3030",
			},
		],
		[fdg.balance, stakingFdg.ownStake, lockingFdg.rawStake, totalFdg]
	);

	const ckiList = useMemo(
		() => [
			{
				id: "1",
				title: "Unused",
				bal: cki.balance ? BD(cki.balance.toString()) : ZERO,
				percent: computeRatio(cki.balance ? BD(cki.balance.toString()) : ZERO, totalCki),
				color: "#C0C0C0",
			},
			{
				id: "2",
				title: "Staked",
				bal: stakingCki.ownStake || ZERO,
				percent: computeRatio(stakingCki.ownStake || ZERO, totalCki),
				color: "#3065EC",
			},
			{
				id: "3",
				title: "Locked",
				bal: lockingCki.rawStake || ZERO,
				percent: computeRatio(lockingCki.rawStake || ZERO, totalCki),
				color: "#EC3030",
			},
			{
				id: "4",
				title: "gCKI",
				bal: gCki.deposit || ZERO,
				percent: computeRatio(gCki.deposit || ZERO, totalCki),
				color: "#18be28",
			},
			{
				id: "5",
				title: "AD",
				bal: compete.ckiBlockedAp || ZERO,
				percent: computeRatio(compete.ckiBlockedAp || ZERO, totalCki),
				color: "#F27D03",
			},
			{
				id: "6",
				title: "DP",
				bal: compete.defenseBal || ZERO,
				percent: computeRatio(compete.defenseBal || ZERO, totalCki),
				color: "#0DF0FF",
			},
		],
		[
			cki.balance,
			stakingCki.ownStake,
			lockingCki.rawStake,
			gCki.deposit,
			compete.ckiBlockedAp,
			compete.defenseBal,
			totalCki,
		]
	);

	const ckiTotalStaked = stakingCki.totalStake;
	const ckiStakeRev = utils.ckiStakeRev;
	const ckiStaked = stakingCki.ownStake;
	const [ckiStakeApy, setCkiApy] = useState(null);
	useEffect(() => {
		if (ckiStakeRev && ckiTotalStaked) {
			setCkiApy((ckiStakeRev * ckiStaked) / (ckiTotalStaked * 24));
		}
	}, [ckiTotalStaked, ckiStakeRev, ckiStaked]);

	const fdgTotalStaked = stakingFdg.totalStake;
	const fdgStakeRev = utils.fdgStakeRev;
	const fdgStaked = stakingFdg.ownStake;
	const [fdgStakeApy, setFdgApy] = useState(null);
	useEffect(() => {
		if (fdgStakeRev && fdgTotalStaked) {
			setFdgApy((fdgStakeRev * fdgStaked) / (fdgTotalStaked * 24));
		}
	}, [fdgTotalStaked, fdgStakeRev, fdgStaked]);

	return (
		<RealApp
			account={account}
			totalCki={totalCki}
			totalFdg={totalFdg}
			ckiList={ckiList}
			fdgList={fdgList}
			ad={dgh}
			createAttack={compete.createAttack}
			compete={compete}
			gCki={gCki}
			cki={cki}
			lockingCki={lockingCki}
			stakingCki={stakingCki}
			utils={utils}
			lockingFdg={lockingFdg}
			ckiStakeApy={ckiStakeApy}
			fdgStakeApy={fdgStakeApy}
			fdg={fdg}
			stakingFdg={stakingFdg}
			ccDistr={ccDistr}
			balance={etherBalance}
		/>
	);
}

export default App;
