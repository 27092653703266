import { useState, useEffect } from "react";
import { closeIcon, infoIcon, plusIcon2 } from "../../SVG";
import DoghChart from "../../DoghChart";
import {
	inputToBnScaled,
	printBD,
	getUnscaledBD,
	BN,
	BD,
	ZERO,
	ZERO_BYTES_32,
} from "interfaces/Numbers";
import { Addresses } from "../../../";
import { computeRatio } from "App";
import { TxIDs } from "../ModalWrapper";
import { useWalletClient } from "wagmi";

// TODO(alexis) : make 4 modals into one...
export default function StakeCKI(props) {
	const {
		account,
		setAlert,
		cookieList,
		balCki,
		ckiInfo,
		approveAll,
		ckiUnused,
		setModal,
		addTx,
		stakeCki,
		ckiStakeRev,
		ckiApprove,
		ckiTotalStaked,
		ckiStaked,
		lockingApy,
		soundEffects,
	} = props;
	const [balance, setBalance] = useState(1000);
	const [value, setValue] = useState(0);
	const [updatedCookieList, setUpdatedCookieList] = useState([...cookieList]);
	const [stakeApy, setStakeApy] = useState(null);

	useEffect(() => {
		if (value !== "" && !isNaN(Number(value))) {
			const toAdd = BD(value).multiply(BD(1e18));
			setStakeApy(
				(ckiStakeRev * BD(ckiStaked).add(toAdd)) / (BD(ckiTotalStaked).add(toAdd) * 24)
			);
		} else {
			setStakeApy((ckiStakeRev * BD(ckiStaked)) / (BD(ckiTotalStaked) * 24));
		}
	}, [ckiTotalStaked, ckiStakeRev, value, ckiStaked]);

	useEffect(() => {
		setBalance(Math.floor(getUnscaledBD(ckiUnused) * 100) / 100);
	}, [ckiUnused]);

	useEffect(() => {
		if (
			typeof value === "string" &&
			((value.charAt(value.length - 1) != "." &&
				value.charAt(value.length - 2) != "." &&
				value.charAt(value.length - 3) != ".") ||
				value[0] == "-" ||
				value[0] == "+")
		) {
			setValue(Math.abs(Math.floor(parseFloat(value.toString()) * 100) / 100));
		}
	}, [value]);

	useEffect(() => {
		setUpdatedCookieList(cookieList);
		if (value && value !== "" && !isNaN(Number(value)) && balCki.compareTo(ZERO) !== 0) {
			const tot = cookieList.reduce((sum, item) => BD(sum).add(BD(item.bal)), BD(0));
			const newValue = BD(value)
				.multiply(BD(10 ** 18))
				.toBigInteger();

			const newCookieList = [
				{
					...updatedCookieList[0],
					percent: computeRatio(BD(BN(cookieList[0].bal).sub(BN(newValue))), tot),
				},
				{
					...updatedCookieList[1],
					percent: computeRatio(BD(BN(cookieList[1].bal).add(BN(newValue))), tot),
				},
				{
					...cookieList[2],
				},
				{
					...cookieList[3],
				},
				{
					...cookieList[4],
				},
				{
					...cookieList[5],
				},
			];

			setUpdatedCookieList(newCookieList);
		}
	}, [value, cookieList, balCki]);

	return (
		<div className="modalStake">
			<div className="modalStake__inner">
				<div className="modalStake__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<div className="modalStake__inner-title">
					<h4>Stake your CKI</h4>
				</div>
				<div className="modalStake__inner-desc">
					<p>
						Stake CKI to earn FDG tokens. Choose the percentage of unused tokens that
						you want to stake.
					</p>
				</div>

				<div className="modalStake__content">
					<div className="modalStake__left">
						<div className="modalStake__left-func">
							<div className="modalStake__left-title">
								<p className="sm">Available Balance:</p>
								<div className="modalStake__left-title-row">
									<p className="sm">{printBD(ckiUnused)}</p>
									<img src={process.env.PUBLIC_URL + "images/logo.svg"} alt="" />
								</div>
							</div>
							<div className="modalStake__left-row">
								<div className="modalStake__left-input">
									<input
										type="text"
										value={!isNaN(value) ? value : ""}
										onChange={(e) => {
											if (
												e.nativeEvent.data !== " " &&
												e.target.value <= balance
											) {
												setValue(e.target.value);
											}
										}}
									/>
									<p>CKI</p>
								</div>
								<button
									className="button secondary"
									onClick={() => {
										soundEffects["click"].play();
										setValue(balance);
									}}
								>
									Max
								</button>
							</div>
							<div className="modalStake__range">
								<div className="modalStake__range-line">
									<span style={{ width: `${(value * 100) / balance}%` }}></span>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 0 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(balance * 0)}
									></button>
									<p>0%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 24 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.25 * 100) / 100)
										}
									></button>
									<p>25%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 49 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.5 * 100) / 100)
										}
									></button>
									<p>50%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 74 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.75 * 100) / 100)
										}
									></button>
									<p>75%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 99 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(balance * 1)}
									></button>
									<p>100%</p>
								</div>
							</div>
						</div>
						<div className="modalStake__left-box">
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p style={{ fontWeight: "bold" }}>Total Yield</p>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												Combined hourly yield from staking and locking.
											</div>
										</div>
									</div>
								</div>
								<div className="modalStake__left-box-row-right">
									<div style={{ display: "flex", alignItems: "center" }}>
										<p style={{ color: "#18be28", fontWeight: "bold" }}>
											{printBD(
												(isNaN(lockingApy) ? 0 : lockingApy) +
													(isNaN(stakeApy) ? 0 : stakeApy)
											)}
										</p>
										<img
											src={process.env.PUBLIC_URL + "images/fudge.svg"}
											alt=""
											style={{ marginLeft: "5px", marginRight: "5px" }} // Adjust margin as needed
										/>
										<p style={{ fontWeight: "bold" }}>/H</p>
									</div>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>From Locking</p>
								</div>
								<div className="modalStake__left-box-row-right">
									<p>{printBD(lockingApy)}</p>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>From Staking</p>
								</div>
								<div className="modalStake__left-box-row-right">
									<p>{printBD(stakeApy)}</p>
								</div>
							</div>
						</div>
						<div
							className="button primary sm"
							onClick={async () => {
								if (account === undefined) {
									soundEffects["error"].play();
									setAlert("Please connect your wallet.");
									return;
								}

								if (value === undefined || isNaN(value)) {
									soundEffects["error"].play();
									setAlert("Please enter a valid number.");
									return;
								}

								if (value === 0) {
									soundEffects["error"].play();
									setAlert("Please enter a non-zero amount.");
									return;
								}

								soundEffects["coin"].play();
								const val = inputToBnScaled(value);

								try {
									setModal(null);
									await approveAll(val);
									addTx(
										TxIDs.STAKE_CKI,
										"Adding stake",
										"Staking " + value.toLocaleString("en-US") + " CKI",
										""
									);
									await stakeCki(val, 0, 0, ZERO_BYTES_32, ZERO_BYTES_32);
								} catch (e) {
									console.error("Error staking CKI:", e);
								}
							}}
						>
							Deposit
						</div>
					</div>
					<div className="modalStake__right">
						<div className="modalStake__right-title">
							<h5 className="sm">CKI Balance</h5>
							<div className="tooltip">
								<div className="tooltip__icon">{infoIcon}</div>
								<div className="tooltip__content">
									<div className="tooltip__content-inner">
										Your new CKI balance and distribution strategy.
									</div>
								</div>
							</div>
						</div>
						<div className="modalStake__right-inner">
							<div className="modalStake__right-chart">
								<div className="modalStake__right-chart-bal">
									<img src="./images/logo.svg" alt="" />
									{printBD(balCki)}
								</div>
								<DoghChart
									data={updatedCookieList.map((item) => item.percent)}
									bgColors={updatedCookieList.map((item) => item.color)}
									percentCircle="75%"
								/>
							</div>
							<div className="modalStake__right-data">
								{updatedCookieList.map((item, index) => {
									return (
										<div className="modalStake__right-data-item" key={index}>
											<div
												className="modalStake__right-data-item-icon"
												style={{ background: item.color }}
											></div>
											{item.title} <span>{item.percent}%</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
