import { useEffect, useMemo } from "react";
import ModalSmall from "./ModalSmall";

export const TxState = {
	SUCCESS: "success",
	FAILURE: "failure",
	LOADING: "loading",
	SIGNING: "signing",
};

export const TxIDs = {
	CLAIM_CKI_LOCK: 0,
	CLAIM_FDG_LOCK: 1,
	CLAIM_CKI_STAKING: 2,
	CLAIM_FDG_STAKING: 3,
	SET_DEFENCE: 4,
	SET_DOUGH: 5,
	ATTACK: 6,
	CLAIM_ALL: 7,
	SET_GCKI: 8,
	CLAIM_GCKI: 9,
	MINT: 10,
	LOCK_CKI: 11,
	UNLOCK_CKI: 19,
	LOCK_FDG: 12,
	UNLOCK_FDG: 18,
	STAKE_CKI: 13,
	UNSTAKE_CKI: 14,
	STAKE_FDG: 15,
	UNSTAKE_FDG: 16,
	SEARCH: 17,
	APPROVE: 18,
};

export default function ModalWrapper(props) {
	const {
		txs,
		setTxs,
		removeTx,
		lockingCki,
		refetchers,
		lockingFdg,
		stakingCki,
		stakingFdg,
		compete,
		utils,
		ccDistr,
		approveState,
	} = props;

	const states = useMemo(
		() => ({
			[TxIDs.CLAIM_CKI_LOCK]: lockingCki.claimState,
			[TxIDs.CLAIM_FDG_LOCK]: lockingFdg.claimState,
			[TxIDs.CLAIM_CKI_STAKING]: stakingFdg.claimState,
			[TxIDs.CLAIM_FDG_STAKING]: stakingCki.claimState,
			[TxIDs.SET_DEFENCE]: compete.defenseState,
			[TxIDs.SET_DOUGH]: compete.apGenState,
			[TxIDs.ATTACK]: compete.attackState,
			[TxIDs.CLAIM_ALL]: utils.claimAllState,
			[TxIDs.MINT]: ccDistr.mintState,
			[TxIDs.SET_GCKI]: utils.gCkiChangeState,
			[TxIDs.LOCK_CKI]: lockingCki.lockState,
			[TxIDs.LOCK_FDG]: lockingFdg.lockState,
			[TxIDs.STAKE_CKI]: stakingCki.stakeState,
			[TxIDs.STAKE_FDG]: stakingFdg.stakeState,
			[TxIDs.SEARCH]: compete.searchState,
			[TxIDs.UNLOCK_CKI]: lockingCki.unlockState,
			[TxIDs.UNLOCK_FDG]: lockingFdg.unlockState,
			[TxIDs.UNSTAKE_CKI]: stakingCki.unstakeState,
			[TxIDs.UNSTAKE_FDG]: stakingFdg.unstakeState,
			[TxIDs.APPROVE]: approveState,
		}),
		[
			lockingCki.claimState,
			lockingFdg.claimState,
			stakingCki.claimState,
			stakingFdg.claimState,
			compete.defenseState,
			compete.apGenState,
			compete.attackState,
			utils.claimAllState,
			utils.gCkiChangeState,
			ccDistr.mintState,
			lockingCki.lockState,
			lockingCki.unlockState,
			lockingFdg.lockState,
			lockingFdg.unlockState,
			stakingCki.stakeState,
			stakingCki.unstakeState,
			stakingFdg.stakeState,
			stakingFdg.unstakeState,
			compete.searchState,
			approveState,
		]
	);

	useEffect(() => {
		setTxs((prevTxs) =>
			prevTxs.map((tx) => {
				if (states[tx.id] === "pending") {
					return { ...tx, state: TxState.LOADING };
				}

				if (states[tx.id] === "success") {
					// Immediately set to success state
					const updatedTx = { ...tx, state: TxState.SUCCESS };

					// Perform background refetches
					const performBackgroundRefetches = async () => {
						const isLongTxn =
							tx.id === TxIDs.UNLOCK_CKI ||
							tx.id === TxIDs.UNLOCK_FDG ||
							tx.id === TxIDs.ATTACK;

						// Different timing for unlock transactions
						const initialDelay = isLongTxn ? 5000 : 1500;
						const interval = isLongTxn ? 4000 : 2000;
						const attempts = isLongTxn ? 10 : 5;

						// Initial delay before starting refetches
						await new Promise((resolve) => setTimeout(resolve, initialDelay));

						// Perform refetches
						for (let i = 0; i < attempts; i++) {
							try {
								await Promise.all(refetchers[tx.id].map((refetch) => refetch()));
							} catch (error) {
								console.error(`Background refetch attempt ${i + 1} failed:`, error);
							}

							if (i < attempts - 1) {
								await new Promise((resolve) => setTimeout(resolve, interval));
							}
						}
					};

					// Start the background refetch process without waiting for it
					performBackgroundRefetches();

					return updatedTx;
				}

				if (states[tx.id] === "error" || states[tx.id] === "exception") {
					return { ...tx, state: TxState.FAILURE };
				}

				return tx;
			})
		);
	}, [states]);

	return (
		<div className="MiniModalWrapper">
			{txs.map((modal) => (
				<ModalSmall key={modal.id} modal={modal} state={modal.state} close={removeTx} />
			))}
		</div>
	);
}
