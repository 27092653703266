import CCLocking from "../abis/CCLocking.json";
import { useReadContracts, useWriteContract } from "wagmi";
import { Chain } from "viem";
import { useMemo, useCallback } from "react";
import { BD, ZERO } from "./Numbers";

type UserState = [bigint, bigint, bigint]; // [ownStake, lastUpdate, alreadyClaimedTotal]
type GlobalState = [bigint, bigint]; // [totalStake, lastUpdate]
type YieldStake = [bigint, number, bigint]; // [stake, league, leagueBoost]

const REFETCH_INTERVAL = 10000;

export function useLockingPool(
	address: `0x${string}`,
	account: `0x${string}` | undefined,
	chain: Chain
) {
	const { data: contractReads, refetch } = useReadContracts({
		contracts: [
			{
				abi: CCLocking,
				address,
				functionName: "usersState",
				args: [account],
			},
			{
				abi: CCLocking,
				address,
				functionName: "globalState",
				args: [],
			},
			{
				abi: CCLocking,
				address,
				functionName: "claimable",
				args: [account],
			},
			{
				abi: CCLocking,
				address,
				functionName: "yieldStakes",
				args: [account],
			},
		],
		query: {
			refetchIntervalInBackground: true,
			refetchInterval: REFETCH_INTERVAL,
		},
	});

	const [userStateResult, globalStateResult, claimableResult, yieldStakeResult] = contractReads || [];
	const userState = userStateResult?.result as UserState;
	const globalState = globalStateResult?.result as GlobalState;
	const claimableValue = claimableResult?.result as bigint;
	const yieldStake = yieldStakeResult?.result as YieldStake;

	const { writeContractAsync: sendLock, status: lockState } = useWriteContract();
	const { writeContractAsync: sendUnlock, status: unlockState } = useWriteContract();
	const { writeContractAsync: sendClaim, status: claimState } = useWriteContract();
	const { writeContractAsync: sendUpdateBoost } = useWriteContract();

	const ownStake = useMemo(
		() => (userState?.[0] ? BD(userState[0].toString()) : ZERO),
		[userState]
	);

	const totalStake = useMemo(
		() => (globalState?.[0] ? BD(globalState[0].toString()) : ZERO),
		[globalState]
	);

	const totalEarned = useMemo(
		() => (userState?.[2] ? BD(userState[2].toString()) : ZERO),
		[userState]
	);

	const rawStake = useMemo(
		() => (yieldStake?.[0] ? BD(yieldStake[0].toString()) : ZERO),
		[yieldStake]
	);

	const league = useMemo(
		() => yieldStake?.[1],
		[yieldStake]
	);

	const leagueBoost = useMemo(
		() => yieldStake?.[2] ? parseInt(yieldStake[2].toString()) : 0,
		[yieldStake]
	);

	const lock = useCallback(
		(amount: bigint, deadline: bigint, v: bigint, r: string, s: string) =>
			sendLock({
				abi: CCLocking,
				address,
				functionName: "stake",
				args: [amount, deadline, v, r, s],
				chain,
				account,
			}),
		[sendLock, address, chain, account]
	);

	const unlock = useCallback(
		(amount: bigint) =>
			sendUnlock({
				abi: CCLocking,
				address,
				functionName: "unstake",
				args: [amount],
				chain,
				account,
			}),
		[sendUnlock, address, chain, account]
	);

	const claim = useCallback(
		() =>
			sendClaim({
				abi: CCLocking,
				address,
				functionName: "claim",
				args: [],
				chain,
				account,
			}),
		[sendClaim, address, chain, account]
	);

	const updateBoost = useCallback(
		(addr: string) =>
			sendUpdateBoost({
				abi: CCLocking,
				address,
				functionName: "updatePoolStrength",
				args: [addr],
				chain,
				account,
			}),
		[sendUpdateBoost, address, chain, account]
	);

	return {
		// Raw states
		userState,
		globalState,
		claimable: claimableValue,
		yieldStake,
		// Computed values
		ownStake,
		totalStake,
		totalEarned,
		rawStake,
		league,
		leagueBoost,
		// Transaction states
		lockState,
		unlockState,
		claimState,
		// Actions
		lock,
		unlock,
		claim,
		updateBoost,
		// Refetch
		refetch,
	};
}