import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { infoIcon, plusIcon2, polRight } from "./SVG";
import DoghChart from "./DoghChart";
import { printBD } from "../interfaces/Numbers";

export default function Balance(props) {
	const { bal, makeBalanceList, balanceList, soundEffects } = props;
	// const [balanceList, setBalanceList] = useState(makeBalanceList());

	// useEffect(() => {
	// 	// Update the dynamicList when generateList changes
	// 	// console.log("reprint");
	// 	setBalanceList(makeBalanceList());
	// }, [makeBalanceList, bal]);

	const { dataType } = useSelector((state) => state.common);
	const wrapper = useRef(null);
	const [active, setActive] = useState(false);
	const [modul, setModul] = useState([]);
	const [tooltip, setTooltip] = useState(false);
	const toggleActive = (e) => {
		e.stopPropagation();
		soundEffects["click"].play();
		setActive(!active);
	};
	useEffect(() => {
		if (dataType === "cookie") {
			setModul(balanceList);
			setTooltip(false);
		} else {
			setModul(balanceList);
			setTooltip(true);
			setTimeout(() => {
				setTooltip(false);
			}, 2000);
		}
	}, [dataType, makeBalanceList, bal]);

	useEffect(() => {
		function handleClickOutside(event) {
		  if (wrapper.current && !wrapper.current.contains(event.target)) {
			setActive(false);
		  }
		}
	  
		if (active) {
		  document.addEventListener('mousedown', handleClickOutside, true);
		  document.addEventListener('touchstart', handleClickOutside, true);
		}
	  
		return () => {
		  document.removeEventListener('mousedown', handleClickOutside, true);
		  document.removeEventListener('touchstart', handleClickOutside, true);
		};
	  }, [active]);

	return (
		<div className={"balance"} ref={wrapper}>
			<button className={"balanceBtn " + (active ? "active" : "")} onClick={toggleActive}>
				<div className="balanceBtn__icon">
					{dataType === "cookie" ? (
						<img src="./images/logo.svg" alt="" />
					) : (
						<img src="./images/fudge.svg" alt="" />
					)}
				</div>
				<p>
					{printBD(bal)} {dataType === "cookie" ? "CKI" : "FDG"}{" "}
				</p>
				<span>
					<img src="./images/icons/chevron-bottom.svg" alt="" />
				</span>
			</button>
			<AnimatePresence>
				{active === true && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.2 }}
						exit={{ opacity: 0 }}
						className={`balanceDrop  ${active ? "active" : ""} `}
					>
						<div className="balanceDrop__title">
							<h5>{dataType === "cookie" ? "Cookie Balance" : "Fudge Balance"}</h5>
							<div className="tooltip">
								<div className="tooltip__icon">{infoIcon}</div>
								<div className="tooltip__content">
									<div className="tooltip__content-inner">
										Total repartition of your
										{dataType === "cookie"
											? " Cookies (CKI)."
											: " Fudge (FDG)."}
									</div>
								</div>
							</div>
						</div>
						<div className="balanceDrop__chart">
							<div className="balanceDrop__chart-bal">
								{dataType === "cookie" ? (
									<img src="./images/logo.svg" alt="" />
								) : (
									<img src="./images/fudge.svg" alt="" />
								)}
								{printBD(bal)}
							</div>
							<DoghChart
								data={modul.map((item) => item.percent)}
								bgColors={modul.map((item) => item.color)}
								percentCircle="75%"
							/>
						</div>
						<div className="balanceDrop__data">
							<div className="balanceDrop__data-icon">{polRight}</div>
							{modul.map((item, index) => {
								return (
									<div className="balanceDrop__data-item" key={index}>
										<div
											className="balanceDrop__data-item-icon"
											style={{ background: item.color }}
										></div>
										{item.title} <span>{item.percent}%</span>
									</div>
								);
							})}
						</div>
						{/* <button className="button primary sm">
							{dataType === "cookie" ? "More CKI" : "More FDG"} {plusIcon2}
						</button> */}
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}
