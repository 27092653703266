import { arrowUpIcon, infoIcon } from "../../../../Base/SVG";
import { BD, printBD, printBDInt, ZERO } from "interfaces/Numbers";

export default function Kitchen(props) {
	const {
		account,
		adBlockedCKI,
		defense,
		gCkiBal,
		gCkiDeposit,
		setModal,
		adDay,
		setAlert,
		realTimeAdBal,
		gCkiEnd,
		soundEffects,
		withdrawGCki,
	} = props;
	const currentUnixTimestampSeconds = Math.floor(Date.now() / 1000);
	const gCkiLeft =
		gCkiEnd && gCkiEnd > 0 ? Math.max(0, gCkiEnd - currentUnixTimestampSeconds) / 86400 : 0;
	return (
		<div className="kitchen">
			<div className="kitchen__top">
				<h5>My Kitchen</h5>
				<p className="sm">
					{/*<span>
						<img src="./images/icons/lock.svg" alt="" />
					</span>*/}
				</p>
			</div>
			<div className="kitchen__inner">
				<div className="kitchenItem">
					<div className="kitchenItem__body">
						<div className="kitchenItem__icon">
							<img
								src={process.env.PUBLIC_URL + "images/icons/kitchen/dough.svg"}
								alt=""
							/>
						</div>
						<div className="kitchenItem__content">
							<div className="kitchenItem__content-row">
								<h6 className="big">Attack Dough</h6>
								<div className="tooltip">
									<div className="tooltip__icon">{infoIcon}</div>
									<div className="tooltip__content">
										<div className="tooltip__content-inner">
											Attack your foes with dough; block CKI to generate more
											DGH.
										</div>
									</div>
								</div>
							</div>
							<p className="sm">
								{printBDInt(realTimeAdBal)} DGH{" "}
								<span>({printBD(BD(adDay / 86400))} / sec)</span>
							</p>
						</div>
					</div>
					<div className="kitchenItem__btns">
						<button
							className="button secondary"
							onClick={() => {
								soundEffects["click"].play();
								setModal("setAttack");
							}}
						>
							{arrowUpIcon} {printBD(adBlockedCKI)} CKI
						</button>
						<button
							type="button"
							className="kitchenItem__btn"
							onClick={() => {
								soundEffects["click"].play();
								setModal("withdrawAttack");
							}}
						>
							withdraw?
						</button>
					</div>
				</div>
				<div className="kitchenItem">
					<div className="kitchenItem__body">
						<div className="kitchenItem__icon">
							<img
								src={process.env.PUBLIC_URL + "images/icons/kitchen/points.svg"}
								alt=""
							/>
						</div>
						<div className="kitchenItem__content">
							<div className="kitchenItem__content-row">
								<h6 className="big">Defence</h6>
								<div className="tooltip">
									<div className="tooltip__icon">{infoIcon}</div>
									<div className="tooltip__content">
										<div className="tooltip__content-inner">
											Secure your locking with defence; lower the likelihood
											that attacks succeed against you.
										</div>
									</div>
								</div>
							</div>
							<p className="sm">{printBDInt(defense)} DP</p>
						</div>
					</div>
					<div className="kitchenItem__btns">
						<button
							className="button secondary"
							onClick={() => {
								soundEffects["click"].play();
								setModal("setDefence");
							}}
						>
							{arrowUpIcon} {printBDInt(defense)} CKI
						</button>
						<button
							type="button"
							className="kitchenItem__btn"
							onClick={() => {
								soundEffects["click"].play();
								setModal("withdrawDefence");
							}}
						>
							withdraw?
						</button>
					</div>
				</div>
				<div className="kitchenItem">
					<div className="kitchenItem__body">
						<div className="kitchenItem__icon">
							<img
								src={process.env.PUBLIC_URL + "images/icons/kitchen/goldCookie.svg"}
								alt=""
							/>
						</div>
						<div className="kitchenItem__content">
							<div className="kitchenItem__content-row">
								<h6 className="big">Golden Cookies</h6>
								<div className="tooltip">
									<div className="tooltip__icon">{infoIcon}</div>
									<div className="tooltip__content">
										<div className="tooltip__content-inner">
											Golden Cookies boost your yield without incurring additional risk.
										</div>
									</div>
								</div>
							</div>
							<p className="sm">
								{printBD(gCkiBal)} GCKI{" "}
								<span>({gCkiLeft.toFixed(2)} days)</span>
							</p>
						</div>
					</div>
					<div className="kitchenItem__btns">
						<button
							className="button secondary"
							onClick={() => {
								soundEffects["click"].play();
								setModal("boost");
							}}
						>
							{arrowUpIcon} {printBD(gCkiDeposit)} CKI
						</button>
						<button
							type="button"
							className="kitchenItem__btn"
							onClick={() => {
								{
									if (account === undefined) {
										soundEffects["error"].play();
										setAlert("Please connect your wallet.");
										return;
									}
									
									if(gCkiDeposit.compareTo(ZERO) === 0) {
										soundEffects["error"].play();
										setAlert(
											"You don't have any Golden Cookies."
										);
									} else if(gCkiLeft === 0) {
										soundEffects["decrease"].play();
										withdrawGCki();
									} else {
										soundEffects["error"].play();
										setAlert(
											"You can only claim back your CKI in " +
												gCkiLeft.toFixed(2) +
												" days."
										);
									}
								}
							}}
						>
							claim back
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
