import { useEffect, useState } from "react";
import { closeIcon } from "../../SVG";
import { useSelector } from "react-redux";
import axios from 'axios';
import { printBD, BD, printUnscaledNoRound, BN } from "../../../interfaces/Numbers";

const TableItem = ({ itemData, index, dataType }) => (
    <tr>
        <td><p>{index + 1}</p></td>
        <td><p>{itemData.address.substring(0, 9) + "..."}</p></td>
        <td>{printUnscaledNoRound(dataType === "cookie" ? itemData.cki : itemData.fdg)}</td>
        <td>{printUnscaledNoRound(dataType === "cookie" ? itemData.cki_h : itemData.fdg_h)}</td>
        <td>{printUnscaledNoRound(itemData.defence)}</td>
    </tr>
);

export default function Board(props) {
    const {
        account,
        balCki,
        balFdg,
        defense,
        fdgLockRev,
        fdgLockedTotal,
        fdgLockedStake,
        ckiLockedTotal,
        ckiLockedStake,
        ckiLockRev,
        setModal,
        ckiStakeApy,
        fdgStakeApy,
        fdgStakedTotal,
        ckiStakedTotal,
        ckiStakeRev,
        fdgStakeRev
    } = props;

    const CACHE_KEY = 'leaderboardData';
    const CACHE_EXPIRATION = 60 * 1000;

    const fetchDataWithCache = async () => {
        const cachedData = localStorage.getItem(CACHE_KEY);
        const cachedTimestamp = localStorage.getItem(`${CACHE_KEY}_timestamp`);

        if (cachedData && cachedTimestamp) {
            const now = new Date().getTime();
            if (now - parseInt(cachedTimestamp) < CACHE_EXPIRATION) {
                return JSON.parse(cachedData);
            }
        }

        console.log("Fetching LB...");
        const response = await axios.get('https://pstzbwybmck4d6t5hfzsbmreay0qalka.lambda-url.us-east-1.on.aws/?query_type=users');
        localStorage.setItem(CACHE_KEY, JSON.stringify(response.data));
        localStorage.setItem(`${CACHE_KEY}_timestamp`, new Date().getTime().toString());
        return response.data;
    };

    const [data, setData] = useState([]);
    useEffect(() => {
        fetchDataWithCache().then(response => {
            setData(response);
        }).catch(error => {
            console.error('There was an error fetching the data!', error);
        });
    }, []);

    const [fdgLockYield, setFdgLockYield] = useState(null);
    useEffect(() => {
        if (fdgLockRev && fdgLockedTotal && fdgLockedStake) {
            setFdgLockYield((fdgLockedStake * fdgLockRev) / (BD(fdgLockedTotal) * 24));
        }
    }, [fdgLockedTotal, fdgLockedStake, fdgLockRev]);
    const [ckiLockYield, setCkiLockYield] = useState(null);
    useEffect(() => {
        if (ckiLockRev && ckiLockedTotal && ckiLockedStake) {
            setCkiLockYield((ckiLockedStake * ckiLockRev) / (BD(ckiLockedTotal) * 24));
        }
    }, [ckiLockedTotal, ckiLockedStake, ckiLockRev]);

    const { dataType } = useSelector((state) => state.common);
    const [userData, setUserData] = useState({
        id: "0",
        no: "N/A",
        cki_fdg: "0",
        sec: "0",
        defense: "0"
    });
    useEffect(() => {
        if (account) { // Very hacky way to swap between strings to numbers for silly standard rounding purposes...
            setUserData({
                no: account,
                cki_fdg: dataType === "cookie" ? Number(printBD(balCki).replace(/,/g, "")) : Number(printBD(balFdg).replace(/,/g, "")),
                sec: dataType === "cookie" ? Number(printBD(fdgStakeApy).replace(/,/g, "")) + Number(printBD(ckiLockYield).replace(/,/g, "")) : Number(printBD(ckiStakeApy).replace(/,/g, "")) + Number(printBD(fdgLockYield).replace(/,/g, "")),
                defense: Number(printBD(defense).replace(/,/g, ""))
            })
        }
    }, [
        account,
        dataType,
        ckiLockYield,
        setModal,
        fdgLockYield,
        fdgStakeApy,
        ckiStakeApy,
        defense
    ]);

    const [lb, setLb] = useState([]);
    useEffect(() => {
        if (ckiLockedTotal && fdgLockedTotal && ckiLockRev && fdgLockRev && userData) {
            setLb([
                ...data.filter(item => !item.address.includes(account)).map(item => {
                    const ckiUnused = BN(item.cki_unused);
                    const ckiLock = BN(item.cki_lock);
                    const ckiStake = BN(item.cki_stake);
                    const ckiDef = BN(item.cki_def);
                    const ckiDghgen = BN(item.cki_dghgen);
                    const ckiGcki = BN(item.cki_gcki);
                    const ckiLockstake = BN(item.cki_lockstake);

                    const fdgUnused = BN(item.fdg_unused);
                    const fdgLock = BN(item.fdg_lock);
                    const fdgStake = BN(item.fdg_stake);
                    const fdgLockstake = BN(item.fdg_lockstake);

                    const totalCki = ckiUnused.add(ckiLock).add(ckiStake).add(ckiDef).add(ckiDghgen).add(ckiGcki);
                    const totalFdg = fdgUnused.add(fdgLock).add(fdgStake);

                    const ckiLockYield = BD(ckiLockedTotal) * 24 === 0 ? 0 :
                        (ckiLockstake.div(BN(1e18)) * ckiLockRev) / (BD(ckiLockedTotal) * 24);

                    const fdgStakeYield = BD(fdgStakedTotal) * 24 === 0 ? 0 :
                        (fdgStake.div(BN(1e18)) * fdgStakeRev) / (BD(fdgStakedTotal) * 24);

                    const fdgLockYield = BD(fdgLockedTotal) * 24 === 0 ? 0 :
                        (fdgLockstake.div(BN(1e18)) * fdgLockRev) / (BD(fdgLockedTotal) * 24);

                    const ckiStakeYield = BD(ckiStakedTotal) * 24 === 0 ? 0 :
                        (ckiStake.div(BN(1e18)) * ckiStakeRev) / (BD(ckiStakedTotal) * 24);

                    return {
                        ...item,
                        cki: totalCki.div(BN(1e18)),
                        fdg: totalFdg.div(BN(1e18)),
                        cki_h: ckiLockYield + fdgStakeYield,
                        fdg_h: fdgLockYield + ckiStakeYield,
                        defence: ckiDef.div(BN(1e18))
                    };
                }),
                ...(account ? [{
                    address: account,
                    cki: userData.cki_fdg,
                    fdg: userData.cki_fdg,
                    cki_h: userData.sec,
                    fdg_h: userData.sec,
                    defence: userData.defense
                }] : [])
            ].sort((a, b) => dataType === "cookie" ? b.cki - a.cki : b.fdg - a.fdg));
        }
    }, [
        data,
        userData,
        account,
        ckiLockedTotal,
        fdgLockedTotal,
        ckiLockRev,
        fdgLockRev,
        fdgStakedTotal,
        ckiStakedTotal,
        ckiStakeRev,
        fdgStakeRev,
    ]);

    let myRank = lb.findIndex(item => item.address === account);
    if (myRank === -1) {
        myRank = "N/A";
    }

    return (
        <div className="modalBoard">
            <div className="modalBoard__inner">
                <div className="modalBoard__inner-close" onClick={() => setModal(null)}>
                    {closeIcon}
                </div>
                <div className="modalBoard__inner-title">
                    <h5>Global Leaderboard</h5>
                </div>
                <div className="modalBoard__inner-desc">
                    <p>Leaderboard of all players in the current season.</p>
                </div>
                <div className="modalBoard__table">
                    <div className="modalBoard__table-inner">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>
                                        <div className="modalBoard__table-th">
                                            <h6>{dataType === "cookie" ? "CKI" : "FDG"}</h6>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="modalBoard__table-th">
                                            <h6>{dataType === "cookie" ? "CKI / h" : "FDG / h"}</h6>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="modalBoard__table-th">
                                            <h6>Defence</h6>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {lb.map((item, index) => (
                                    <TableItem
                                        itemData={item}
                                        index={index}
                                        key={index}
                                        dataType={dataType}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="modalBoard__table extra">
                    <div className="modalBoard__table-inner">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>
                                        <div className="modalBoard__table-th">
                                            <h6>{dataType === "cookie" ? "CKI" : "FDG"}</h6>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="modalBoard__table-th">
                                            <h6>{dataType === "cookie" ? "CKI / h" : "FDG / h"}</h6>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="modalBoard__table-th">
                                            <h6>Defence</h6>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><p>{myRank !== "N/A" ? myRank + 1 : "N/A"}</p></td>
                                    <td><p>{userData.no === "N/A" ? "N/A" : userData.no.substring(0, 9) + "..."}</p></td>
                                    <td>{printUnscaledNoRound(userData.cki_fdg)}</td>
                                    <td>{printUnscaledNoRound(userData.sec)}</td>
                                    <td>{printUnscaledNoRound(userData.defense)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}